
<style *ngIf="configs.title != 'Kubendran'"> 
    .sidenav {
   height: 100%;
   width: 0;
   position: fixed;
   top: 0;
   left: 0;
   background-color: white;
   overflow-x: hidden;
   transition: 0.1s;
   padding-top: 60px;
 }
 
 .sidenav a {
   padding: 8px 8px 8px 32px;
   text-decoration: none;
   font-size: 25px;
   color: #818181;
   display: block;
   transition: 0.3s;
 }
 
 .sidenav a:hover {
   color: #f1f1f1;
 }
 
 .sidenav .closebtn {
   position: absolute;
   top: 0;
   right: 25px;
   font-size: 36px;
   margin-left: 50px;
 }
     
     /* .container-fluid-header-sticky-header
     {
         background-color:#2a7d2e;  
     } */
     /* @media (max-width:1100px)  {
         .container-fluid-header-sticky-header
     {
         background-color:#FBCB0C;  
     }
     } */
     @media screen and (min-width:0px) {
         p{
         font-size: 4vw;
     }
         .container-fluid-header-sticky-header{
     display: none;

 }
 .container-fluid-header-sticky-header1{
          display: block;
 }
     
 }
 @media screen and (min-width:1100px) {
     p{
         font-size: 1vw;
     }
     
     .container-fluid-header-sticky-header{
          display: block;
         background-color:white;       
         
 }
 .container-fluid-header-sticky-header1{
          display: none;
 }
 }
   
    </style>
    <style *ngIf="configs.title === 'Kubendran'">
        .sidenav {
       height: 100%;
       width: 0;
       position: fixed;
       top: 0;
       left: 0;
       background-color: #2a7d2e;
       overflow-x: hidden;
       transition: 0.1s;
       padding-top: 60px;
     }
     
     .sidenav a {
       padding: 8px 8px 8px 32px;
       text-decoration: none;
       font-size: 25px;
       color: #818181;
       display: block;
       transition: 0.3s;
     }
     
     .sidenav a:hover {
       color: #f1f1f1;
     }
     
     .sidenav .closebtn {
       position: absolute;
       top: 0;
       right: 25px;
       font-size: 36px;
       margin-left: 50px;
     }
         
         /* .container-fluid-header-sticky-header
         {
             background-color:#2a7d2e;  
         } */
         /* @media (max-width:1100px)  {
             .container-fluid-header-sticky-header
         {
             background-color:#FBCB0C;  
         }
         } */
         @media screen and (min-width:0px) {
             p{
             font-size: 4vw;
         }
             .container-fluid-header-sticky-header{
         display: none;
     }
     .container-fluid-header-sticky-header1{
              display: block;
     }
         
     }
     @media screen and (min-width:1100px) {
         p{
             font-size: 1vw;
         }
         
         .container-fluid-header-sticky-header{
              display: block;
             background-color:#2a7d2e;
            
             
     }
     .container-fluid-header-sticky-header1{
              display: none;
     }
     }

     @media  (max-width:375px) {
         p{
             font-size:4.5vw;
         }
         
         .container-fluid-header-sticky-header{
              display: none;
             background-color:#2a7d2e;
            
             
     }
     .container-fluid-header-sticky-header1{
              display: flex;
     }
     }
       
        </style>
         <div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'Kubendran'">
            <header>
                <div class="row" >
                    <div class="col-1">
                        <img [src]="logoImage" alt="Logo Image" style="padding:18px"class="logo-image custom-pointer" height="80" width="80" alt="Logo"
                        [routerLink]="['/']"> 
                    </div>
                    <div class="col-4"  style="margin-left:25vw"> 
                        <div class="inq-menu-st row justify-content-center">
                            <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                                    data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                                    class="navbar-toggler"><span class="navbar-toggler-icon"></span><span>Menu</span></button>
                                <div id="navbarNav" class="collapse navbar-collapse">
                                    <ul class="navbar-nav" style="padding:20px;">
                                        <li class="nav-item" id="home" style="margin-left: 0.1rem !important"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                                href="#/"><p>HOME</p></a></li>
                                                <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                    <a href="javascript:;" class="nav-link menu-text" (click)="productsListkubendran('FRUITS')"><p>FRUITS</p></a>
                                                    <app-new-navbarkub  [category]="fruits"></app-new-navbarkub>
                                                    </li>
                                                    <li class="header-cart account-popup1" id="shop" style="margin-left: 2rem !important">
                                                        <a href="javascript:;" class="nav-link menu-text" (click)="productsListkubendran('VEGETABLES')"><p>VEGETABLES</p></a>
                                                        <app-new-navbarkub [category]="vegetables"></app-new-navbarkub>
                                                        </li>
                                        <!-- <li class="header-cart account-popup1" id="shop">
                                            <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">SHOP</a>
                                             <app-new-navbar></app-new-navbar>
                                            </li> -->
                                            <!-- <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                <a href="javascript:;" class="nav-link menu-text" (click)="productsListkubendran('OTHERS')"><p>OTHER DEPARTMENTS</p></a>
                                                 <app-new-navbarkub [category]="others"></app-new-navbarkub>
                                                </li> -->
                                                <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                    <a href="#/about-us" class="nav-link menu-text"><p>PAGES</p></a>
                                                     <app-new-navbarkub [category]="pages"></app-new-navbarkub>
                                                    </li>
                            
                                                
                                        <!-- <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                                                href="#/contact-us">CONTACT US</a></li> -->
                                                
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        </div>
                    <div class="col-3"  style="margin-top: 2%">
                        
                            <ul class="d-flex">
                                <li class="d-flex header-cart account-popup1" style="margin-right: 18%;
                                margin-left: 20%;">
                                    <a href="javascript:;"  style="text-decoration: none !important;color: white !important;" (click)="search()">
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </a>
                                    <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                                        <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                <div *ngFor="let category of allCategories">
                                                                    <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                </div>                                      
                                                            </div>
                                                          </div>
                                                        <input type="text" placeholder="Search here."
                                                            class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                        <div *ngIf="preList.length != 0">
                                                            <div class="autocomplete-items2">
                                                                <div *ngFor="let preItem of preList">
                                                                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="input-group-btn">
                                                            <button class="btn btn-solid" >
                                                                <i class="fa fa-search" (click)="savePreList1()"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                            </form>
                                            
                                        </div>
                                        
                                
                                    </div>
                                </li>
                                
                                <!-- <li>
                                <div class="input-group">
                                    <input type="text" class="form-control" placeholder="Search">
                                    <div class="input-group-append">
                                      <button class="btn btn-secondary" style="border-radius: 0px!important;" type="button">
                                        <i class="fa fa-search"></i>
                                      </button>
                                    </div>
                                  </div>
                                </li> -->
                                
                                <li class="d-flex" style="margin-right: 12%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                                    <a href="javascript:;"  style="text-decoration: none !important;color: white !important;">
                                        <span class="fa fa-user" style="font-size:25px">
                                        </span> Login
                                    </a>
                                </li>
        
                                <li class="d-flex" style="margin-right: 3%;" *ngIf="appService.userLoggedIn">
                                    <div class="dropdown">
                                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: white !important;"><span
                                                class="lnr lnr-user">
                                                {{ appService.userName.substring(0,18) }}...
                                            </span>
          <!-- /style="white-space: break-spaces;"                              -->
                                        </a>
                                        <div id="dropdown" class="dropdown-menu" style="background-color: white !important;" aria-labelledby="dropdownMenuButton">
                                            <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #2a7d2e !important;">My Account</a>
                                            <a class="logout-cutom dropdown-item" routerLink="my-orders" style="color: #2a7d2e !important;">My Order</a>
                                            
                                            <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: #2a7d2e !important;">Logout</a>
                                        </div>
                                    </div>
                                </li>
        
                                <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                                    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: white !important;">
                                        <span class="fa fa-shopping-basket" style="font-size:25px">
                                            <span style="position: absolute;
                                            background: #FBCB0C;
                                            width: 18px;
                                            height: 18px;
                                            border-radius: 9px;
                                            top: -10px;
                                            right: 9%;" >
                                           
                                                <span style="  color: #fff;
                                                position: absolute;
                                                left: 0.2rem;
                                                font-size: 14px;
                                                font-weight: bold;">{{appService.totalCartItems}}</span>
                                            </span>
                                        </span>  
                                         <!-- <span class="menu-title">
                                            Cart
                                        </span> -->
                                    </a>
                                    <app-mini-cart></app-mini-cart>
                                </li>
                            </ul>
                    
                    </div>
                </div>
                
                    
        
            </header>
        </div>
            <!-- </div> -->
            <div class="container-fluid-header-sticky-header1" style="background-color:#2a7d2e;height: 12vw;
            padding: 2vw;" *ngIf="configs.title === 'Kubendran'">
                <header>
                    <div>
        <ul style="display:flex;gap: 10px;">
            <li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:white" (click)="openNavkub()">&#9776;</span>
            </li>
            <li>
                <img [src]="logoImage" alt="Logo Image" style="width: 20vw;
                height: 10vw;
                padding: 1vw;margin-left: 2vw;margin-right:25vw;margin-top: 1px;"class="logo-imagekub custom-pointer" alt="Logo"
                        [routerLink]="['/']"> 
            </li>
            <li class="d-flex header-cart account-popup1">
                                    <a href="javascript:;" (click)="search()">
                                        <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: white !important;    font-size: 5vw;
                                        margin-left: 1vw;
                                        margin-top: 2vw;" (click)="opensearchnavkub()"></i>
                                    </a>
                                    <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                                        <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                <div *ngFor="let category of allCategories">
                                                                    <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                </div>                                      
                                                            </div>
                                                          </div>
                                                       
                                                    </div>
                                            </form>
                                            
                                        </div>
                                        
                                
                                    </div>
                                </li>
                                <li class="d-flex" style="    margin-left: 2vw;
                                margin-top: 1.5vw;margin-right: 2vw;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                                    <a href="javascript:;"  style="text-decoration: none !important;color: white !important;">
                                        <span class="fa fa-user" style="font-size:6vw">
                                        </span>
                                    </a>
                                </li>
        
                                <li class="d-flex" style="margin-left:3vw;" *ngIf="appService.userLoggedIn">
                                    <div class="dropdown">
                                        <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: white !important;font-size:3vw;margin-top:1vw"><span
                                                class="lnr lnr-user">
                                            </span>
                                            {{ appService.userName.substring(0,4) }}...
                                            <!-- <p style="white-space: break-spaces;font-size: 2vw;">{{ appService.userName.substring(0,4) }}...</p> -->
                                        </a>
                                        <div id="dropdown" class="dropdown-menu" style="background-color: white !important;" aria-labelledby="dropdownMenuButton">
                                            <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #2a7d2e !important;">My Account</a>
                                            <a class="logout-cutom dropdown-item" routerLink="my-orders" style="color: #2a7d2e !important;">My Order</a>
                                            
                                            <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: #2a7d2e !important;">Logout</a>
                                        </div>
                                    </div>
                                </li>
                                <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                                    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: white !important;">
                                        <span class="fa fa-shopping-basket" style="font-size: 5vw;
                                        margin-top: 2vw;
                                        margin-left: 1vw;">
                                            <span style="position: absolute;
                                            background: #FBCB0C;
                                            width: 3vw;
                                            height: 2.5vw;
                                            border-radius: 1vw;" >
                                           
                                                <span style="color: #fff;
                                                position: absolute;
                                                left: 0.3rem;
                                                font-size: 2vw;
                                                font-weight: bold;">{{appService.totalCartItems}}</span>
                                            </span>
                                        </span>  
                                         <!-- <span class="menu-title">
                                            Cart
                                        </span> -->
                                    </a>
                                    <app-mini-cart></app-mini-cart>
                                </li>
        </ul>
        
                    </div>
                    <div id="mySidenav1" class="sidenav">
                        <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closeNavkub11()"><p>&#8592;</p></a>
                                                <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myfruits1','FRUITS')"><p>FRUITS &#8594;</p></a>
                        
                        <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p>VEGETABLES &#8594;</p></a>
                        <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myothers1','OTHERS')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->
                                   
                  
                      </div>
                      <div id="myfruits1" class="sidenav">
                        <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub1()">&#8592;FRUITS </span>
                        
                        <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub>
                    
                      </div>
                      <div id="myvegetables1" class="sidenav">
                        <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub1()">&#8592;VEGETABLES </span>
                        
                        <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub>
                    
                      </div>
                      <!-- <div id="myothers1" class="sidenav">
                        <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub1()">&#8592;OTHER DEPARTMENTS </span>
                        
                        <app-new-navbarkub  [category]="othersres"></app-new-navbarkub>
                    
                      </div> -->
                    <div id="searchnav" class="sidenav">
                        <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closesearchnavkub()"><p>&#8592;</p></a>
                        <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                        <input type="text" placeholder="Search here."
                        class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                        <ul *ngFor="let preItem of preList">
                            <li class="" (click)="savePreList(preItem)" style="color:white;    margin-left: 10px !important;">{{preItem.name}}</li>
                        </ul>
                        </form>
                        
                        <!-- <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                            <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                    <div *ngFor="let category of allCategories">
                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                    </div>                                      
                                                </div>
                                              </div>
                                            <input type="text" placeholder="Search here."
                                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                            <div *ngIf="preList.length != 0">
                                                <div class="autocomplete-items2">
                                                    <div *ngFor="let preItem of preList">
                                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group-btn">
                                                <button class="btn btn-solid" >
                                                    <i class="fa fa-search" (click)="savePreList1()"></i>
                                                </button>
                                            </div>
                                        </div>
                                </form>
                                
                            </div>
                            
                    
                        </div> -->

                    </div>
                    <div id="mySidenav" class="sidenav">
                        <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closeNavkub1()"><i class="fa fa-arrow-circle-left" style='font-size:35px;position: relative;bottom: 40px;left: 7px;'></i></a>
                        <a class="nav-link menu-text" ng-reflect-router-link="/"
                                                href="#/" (click)="closeNavkub1()"><p>HOME</p></a>
                                                <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myfruits')"><p>FRUITS <span style='font-size:16px;'>&#8594;</span></p></a>
                        
                        <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myvegetables')"><p>VEGETABLES <span style='font-size:16px;'>&#8594;</span></p></a>
                        <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myothers')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->
                                   
                  
                      </div>  
                      <div id="myfruits" class="sidenav">
                        <span style="font-size: 6vw;cursor:pointer;color:white;position: relative;left: 1px;" (click)="openNavkub()">FRUITS<i class="fa fa-arrow-circle-left" style='font-size:35px; position: absolute;left: 150px;top:1px;'></i> </span>

                    
                     
                        <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub>
                    
                      </div>
                      <div id="myvegetables" class="sidenav">
                        <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub()">VEGETABLES<i class="fa fa-arrow-circle-left" style='font-size:35px; position: absolute;left: 150px;top: 63px;'></i></span>
                        
                        <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub>
                    
                      </div>
                      <!-- <div id="myothers" class="sidenav">
                        <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub()">&#8592;OTHER DEPARTMENTS </span>
                        
                        <app-new-navbarkub  [category]="othersres"></app-new-navbarkub>
                    
                      </div> -->
                    
                        
            
                </header>
            </div>

            <div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'AvonSeals'" style="background-color: white !important;">
                <!-- <header>
                    <div class="row" >
                        <div class="col-1" style="margin-left: 450px;transform: translate(80px, 0px);">
                           
                            <img src="../../assets/images/avonseals/Avon Seals.png" alt="Logo Image" style="padding:8px"class="logo-image custom-pointer" height="130" width="130" alt="Logo"
                            [routerLink]="['/']"> 
                        </div>
                        
                        <div class="col-3"  style="margin-top: 2%; margin-left: 250px; transform: translate(160px, 10px);">
                            
                                <ul class="d-flex">
                                    <li class="d-flex header-cart account-popup1" style="margin-right: 18%;
                                    margin-left: 20%;">
                                        <a href="javascript:;"  style="text-decoration: none !important;color: black !important;" (click)="search()">
                                            <i class="fa fa-search" aria-hidden="true"></i>
                                        </a>
                                        <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                                            <div class="row" style="border-bottom : 2px dashed black;margin: 5px;">
                                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                    <div *ngFor="let category of allCategories">
                                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                    </div>                                      
                                                                </div>
                                                              </div>
                                                            <input type="text" placeholder="Search here."
                                                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                            <div *ngIf="preList.length != 0">
                                                                <div class="autocomplete-items2">
                                                                    <div *ngFor="let preItem of preList">
                                                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="input-group-btn">
                                                                <button class="btn btn-solid" >
                                                                    <i class="fa fa-search" (click)="savePreList1()"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                </form>
                                                
                                            </div>
                                            
                                    
                                        </div>
                                    </li>
                                    
                                
                                    
                                    <li class="d-flex" style="margin-right: 12%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                                        <a href="javascript:;"  style="text-decoration: none !important;color: black !important;">
                                            <span class="fa fa-user" style="font-size:25px">
                                            </span> Login
                                        </a>
                                    </li>
            
                                    <li class="d-flex" style="margin-right: 3%;" *ngIf="appService.userLoggedIn">
                                        <div class="dropdown">
                                            <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: white !important;"><span
                                                    class="lnr lnr-user">
                                                    {{ appService.userName.substring(0,18) }}...
                                                </span>
             
                                            </a>
                                            <div id="dropdown" class="dropdown-menu" style="background-color: black!important;" aria-labelledby="dropdownMenuButton">
                                                <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: black !important;">My Account</a>
                                                <a class="logout-cutom dropdown-item" routerLink="my-orders" style="color: black!important;">My Order</a>
                                                
                                                <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: black !important;">Logout</a>
                                            </div>
                                        </div>
                                    </li>
            
                                    <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                                        <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: black !important;">
                                            <span class="fa fa-shopping-basket" style="font-size:25px">
                                                <span style="position: absolute;
                                                background: #FBCB0C;
                                                width: 18px;
                                                height: 18px;
                                                border-radius: 9px;
                                                top: -10px;
                                                right: 9%;" >
                                               
                                                    <span style="  color: #fff;
                                                    position: absolute;
                                                    left: 0.2rem;
                                                    font-size: 14px;
                                                    font-weight: bold;">{{appService.totalCartItems}}</span>
                                                </span>
                                            </span>  
                                          
                                        </a>
                                        <app-mini-cart></app-mini-cart>
                                    </li>
                                </ul>
                        
                        </div>
                    </div>
                    
                        
            
                </header> -->
                <header>
                    <div class="row" style="display: inline-flex; justify-content: normal; width: 100%;">
                        <div class="col-1" style="margin-left: 44%;margin-right: 23%;">
                            
                            <img src="../../assets/images/avonseals/Avon Seals.png" alt="Logo Image" style="padding:8px; object-fit: contain;"class="logo-image custom-pointer" height="140" width="185" alt="Logo"
                            [routerLink]="['/']"> 
                        </div>
                        
                        <div class="col-3"  style="margin-top: 5%;margin-right:inherit;">
                            
                                <ul class="d-flex">
                                    <li class="d-flex header-cart account-popup1" style="margin-right: 20%;
                                    margin-left: 20%;">
                                        <a href="javascript:;"  style="text-decoration: none !important;color: black !important;" (click)="search()">
                                            <i class="fa fa-search" aria-hidden="true"></i>
                                        </a>
                                        <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;margin-top: -30px;">
                                            <div class="row" style="border-bottom : 2px;margin: 5px;">
                                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                    <div *ngFor="let category of allCategories">
                                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                    </div>                                      
                                                                </div>
                                                              </div>
                                                            <input type="text" placeholder="Search here."
                                                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                            <div *ngIf="preList.length != 0">
                                                                <div class="autocomplete-items2">
                                                                    <div *ngFor="let preItem of preList">
                                                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="input-group-btn">
                                                                <button class="btn btn-solid" >
                                                                    <i class="fa fa-search" (click)="savePreList1()"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                </form>
                                                
                                            </div>
                                            
                                    
                                        </div>
                                    </li>
                                    
                                    <!-- <li>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search">
                                        <div class="input-group-append">
                                          <button class="btn btn-secondary" style="border-radius: 0px!important;" type="button">
                                            <i class="fa fa-search"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </li> -->
                                    
                                    <li class="d-flex" style="margin-right: 25%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                                        <a href="javascript:;"  style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">
                                            <span class="fa fa-user" style="font-size:25px">
                                            </span> Login
                                        </a>
                                    </li>
            
                                    <li class="d-flex" style="margin-right: 3%;" *ngIf="appService.userLoggedIn">
                                        <div class="dropdown">
                                            <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: #1d1b1a !important;"><span
                                                    class="lnr lnr-user">
                                                    {{ appService.userName.substring(0,18) }}
                                                </span>
              <!-- /style="white-space: break-spaces;"                              -->
                                            </a>
                                            <div id="dropdown" class="dropdown-menu" style="background-color: white!important;" aria-labelledby="dropdownMenuButton">
                                                <a class="logout-cutom dropdown-item" routerLink="my-account" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">My Account</a>
                                                <a class="logout-cutom dropdown-item" routerLink="my-orders" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">My Order</a>
                                                
                                                <a class="logout-cutom dropdown-item" (click)="logOut()" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">Logout</a>
                                            </div>
                                        </div>
                                    </li>
            
                                    <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                                        <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: black !important;">
                                            <span class="fa fa-shopping-basket" style="font-size:25px">
                                                <span style="position: absolute;
                                                background: #FBCB0C;
                                                width: 18px;
                                                height: 18px;
                                                border-radius: 9px;
                                                top: -10px;
                                                right: 9%;" >
                                               
                                                    <span style="  color: #fff;
                                                    position: absolute;
                                                    left: 0.2rem;
                                                    font-size: 14px;
                                                    font-weight: bold;">{{appService.totalCartItems}}</span>
                                                </span>
                                            </span>  
                                             <!-- <span class="menu-title">
                                                Cart
                                            </span> -->
                                        </a>
                                        <app-mini-cart></app-mini-cart>
                                    </li>
                                </ul>
                        
                        </div>
                    </div>
                    
                        
            
                </header>
                <hr style="width:700px ;border: 1px solid rgb(27, 26, 26); margin-left: auto; margin-right: auto; margin-top: -8px;" >
                <header>
                    <div class="row2" style="height: 71px;">
                        <div class="col-4"  style="margin-left: 33vw; height: 50px; margin-top: -15px; margin-bottom: 15px;"> 
                            <div class="inq-menu-st row justify-content-center">
                                <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                                        data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                                        class="navbar-toggler"><span class="navbar-toggler-icon"></span><span>Menu</span></button>
                                    <div id="navbarNav" class="collapse navbar-collapse">
                                        <!-- <ul class="navbar-nav" style="padding:20px;">
                                            <li class="nav-item" id="home" style="margin-left: 0.1rem !important"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                                    href="#/"><p style="color:black">HOME</p></a></li>
                                                    <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                        <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p style="color:black">PRODUCTS</p></a>
                                                      
                                                        </li>
                                                        <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                            <a href="javascript:;" class="nav-link menu-text" ><p style="color:black">ABOUTUS</p></a>
                                                           
                                                            </li>
                                           
                                                    <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                        <a href="#/about-us" class="nav-link menu-text"><p style="color:black">CONTACTUS</p></a>
                                                       
                                                        </li>
                                
                                                    
                                         
                                                    
                                        </ul> -->
                                        <ul class="navbar-nav" style="padding:20px;">
                                            <li class="nav-item" id="home" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                                    href="#/"><p style="font-weight: bold;color:black">HOME</p></a></li>
                                                    <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                        <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important; font-weight: bold;color:black">PRODUCTS</p></a>
                                                        <!-- <app-new-navbarkub [category]="fruits"></app-new-navbarkub> -->
                                                        </li>
                                                        <li class="header-cart account-popup1" id="shop" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important">
                                                            <a href="javascript:;" class="nav-link menu-text"href="#/about-us" ><p style="font-weight: bold;color:black;width: 150%;">ABOUT US</p></a>
                                                            <!-- <app-new-navbarkub [category]="vegetables"></app-new-navbarkub> -->
                                                            </li>
                                            <!-- <li class="header-cart account-popup1" id="shop">
                                                <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">SHOP</a>
                                                 <app-new-navbar></app-new-navbar>
                                                </li> -->
                                                <!-- <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                    <a href="javascript:;" class="nav-link menu-text" (click)="productsListkubendran('OTHERS')"><p>OTHER DEPARTMENTS</p></a>
                                                     <app-new-navbarkub [category]="others"></app-new-navbarkub>
                                                    </li> -->
                                                    <li class="header-cart account-popup1" id="shop" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important">
                                                        <a href="#/contact-us" class="nav-link menu-text"><p style="font-weight: bold;color:black;width: 150%;">CONTACT US</p></a>
                                                         <!-- <app-new-navbarkub [category]="pages"></app-new-navbarkub> -->
                                                        </li>
                                
                                                    
                                            <!-- <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                                                    href="#/contact-us">CONTACT US</a></li> -->
                                                    
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                            </div>
                    </div>
                </header>
            </div>

<div class="container-fluid-header-sticky-header1" style="background-color:white;height: 12vw;
                padding: 2vw;" *ngIf="configs.title === 'AvonSeals'">
                    <header>
                        <div>
            <ul style="display:flex;gap: 0px;">
                <li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:black" (click)="openNavkub()">&#9776;</span>
                </li>
                <li>
                    <!-- <img [src]="logoImage" alt="Logo Image" style="width: 20vw;
                    height: 10vw;
                    padding: 1vw;margin-left: 2vw;margin-right:25vw;margin-top: 1px;"class="logo-imagekub custom-pointer" alt="Logo"
                            [routerLink]="['/']">  -->
                            <img src="../../assets/images/avonseals/Avon Seals.png" alt="Logo Image" style="width: 20vw;
                            height: 15vw;
                            ;margin-left: 25vw;margin-right:15vw;"class="logo-imagekub custom-pointer" alt="Logo"
                                    [routerLink]="['/']"> 
                </li>
                <li class="d-flex header-cart account-popup1">
                                        <a href="javascript:;" (click)="search()">
                                            <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: black !important;    font-size: 5vw;
                                            margin-left: 1vw;
                                            margin-top: 2vw;" (click)="opensearchnavkub()"></i>
                                        </a>
                                        <!-- <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                                            <div class="row" style="border-bottom : 2px;margin: 5px;">
                                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                    <div *ngFor="let category of allCategories">
                                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                    </div>                                      
                                                                </div>
                                                              </div>
                                                           
                                                        </div>
                                                </form>
                                                
                                            </div>
                                            
                                    
                                        </div> -->
                                    </li>
                                    <li class="d-flex" style="    margin-left: 2vw;
                                    margin-top: 1.5vw;margin-right: 2vw;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                                        <a href="javascript:;"  style="text-decoration: none !important;color: black !important;">
                                            <span class="fa fa-user" style="font-size:6vw">
                                            </span>
                                        </a>
                                    </li>
            
                                    <li class="d-flex" style="margin-left:3vw;" *ngIf="appService.userLoggedIn">
                                        <div class="dropdown">
                                            <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: black !important;font-size:3vw;margin-top:1vw"><span
                                                    class="lnr lnr-user">
                                                </span>
                                                {{ appService.userName.substring(0,4) }}
                                                <!-- <p style="white-space: break-spaces;font-size: 2vw;">{{ appService.userName.substring(0,4) }}...</p> -->
                                            </a>
                                            <div id="dropdown" class="dropdown-menu" style="background-color: white !important;" aria-labelledby="dropdownMenuButton">
                                                <div id="dropdown" class="dropdown-menu show" style=" transform: translate3d(-47px, -20px, 0px) !important;">
                                                <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: black !important;">My Account</a>
                                                <a class="logout-cutom dropdown-item" routerLink="my-orders" style="color: black !important;">My Order</a>
                                                
                                                <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: black !important;">Logout</a>
                                            </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                                        <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: black !important;">
                                            <span class="fa fa-shopping-basket" style="font-size: 5vw;
                                            margin-top: 2vw;
                                            margin-left: 1vw;">
                                                <span style="position: absolute;
                                                background: #FBCB0C;
                                                width: 5vw;
                                                height: 4.5vw;
                                                border-radius: 2vw;margin-left:-15px;top:-1px;" >
                                               
                                                    <span style="color: #fff;
                                                    position: absolute;
                                                    left: 0.3rem;
                                                    font-size: 3vw;
                                                    font-weight: bold;">{{appService.totalCartItems}}</span>
                                                </span>
                                            </span>  
                                             <!-- <span class="menu-title">
                                                Cart
                                            </span> -->
                                        </a>
                                        <app-mini-cart></app-mini-cart>
                                    </li>
            </ul>
            
                        </div>
                        <div id="mySidenav1" class="sidenav" style="background-color:#fff">
                            <a href="javascript:void(0)" style="float:right;font-size:50px;color: black;" class="nav-link menu-text" (click)="closeNavkub11()"><p>&#8592;</p></a>
                                                    <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myfruits1','FRUITS')"><p style="color:black">HOME&#8594;</p></a>
                            
                            <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p style="color:black">PRODUCTS &#8594;</p></a>
                            <a href="javascript:void(0)"  style="color: black;" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p style="color:black">ABOUT US &#8594;</p></a>
                            <a href="javascript:void(0)"  style="color: black;" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p style="color:black">CONTACT US &#8594;</p></a>
                            <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myothers1','OTHERS')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->
                                       
                      
                          </div>
                          <div id="myfruits1" class="sidenav">
                            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;HOME </span>
                            
                            <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub>
                        
                          </div>
                          <div id="myvegetables1" class="sidenav">
                            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;PRODUCTS </span>
                            
                            <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub>
                        
                          </div>
                          <div id="myvegetables1" class="sidenav">
                            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;ABOUT US </span>
                            
                            <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub>
                        
                          </div>
                          <div id="myvegetables1" class="sidenav">
                            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;CONTACT US </span>
                            
                            <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub>
                        
                          </div>
                          <!-- <div id="myothers1" class="sidenav">
                            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub1()">&#8592;OTHER DEPARTMENTS </span>
                            
                            <app-new-navbarkub  [category]="othersres"></app-new-navbarkub>
                        
                          </div> -->
                        <div id="searchnav" class="sidenav" style="background-color:#fff">
                            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closesearchnavkub()"><p>&#8592;</p></a>
                            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                            <input type="text" placeholder="Search here."
                            class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                            <ul *ngFor="let preItem of preList">
                                <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">{{preItem.name}}</li>
                            </ul>
                            </form>
                            
                            <!-- <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                                <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                    <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                        <div *ngFor="let category of allCategories">
                                                            <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                        </div>                                      
                                                    </div>
                                                  </div>
                                                <input type="text" placeholder="Search here."
                                                    class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                <div *ngIf="preList.length != 0">
                                                    <div class="autocomplete-items2">
                                                        <div *ngFor="let preItem of preList">
                                                            <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="input-group-btn">
                                                    <button class="btn btn-solid" >
                                                        <i class="fa fa-search" (click)="savePreList1()"></i>
                                                    </button>
                                                </div>
                                            </div>
                                    </form>
                                    
                                </div>
                                
                        
                            </div> -->
    
                        </div>
                        <div id="mySidenav" class="sidenav" style="background-color:#fff">
                            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closeNavkub1()"><p>&#8592;</p></a>
                            <a class="nav-link menu-text" ng-reflect-router-link="/"
                                                    href="#/" (click)="closeNavkub1()"><p style="color:black">HOME</p></a>
                                                    <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myfruits')"><p>HOME &#8594;</p></a> -->
                            
                            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsList()"><p style="color:black">PRODUCTS</p></a>
                            <a href="javascript:void(0)" class="nav-link menu-text" href="#/about-us" (click)="routeTo()"><p style="color:black">ABOUT US</p></a>
                            <a href="javascript:void(0)" class="nav-link menu-text" href="#/contact-us" (click)="routeTo()"><p style="color:black">CONTACT US</p></a>
                            <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myothers')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->
                                       
                      
                          </div>  
                          <div id="myfruits" class="sidenav" style="background-color:#fff">
                            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">&#8592;HOME </span>
                            
                            <!-- <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub> -->
                        
                          </div>
                          <div id="myvegetables" class="sidenav">
                            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">PRODUCTS </span>
                            
                            <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->
                        
                          </div>
                          <div id="myvegetables" class="sidenav">
                            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">ABOUT US </span>
                            
                            <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->
                        
                          </div>
                          <div id="myvegetables" class="sidenav">
                            <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">CONTACT US </span>
                            
                            <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->
                        
                          </div>
                          <!-- <div id="myothers" class="sidenav">
                            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub()">&#8592;OTHER DEPARTMENTS </span>
                            
                            <app-new-navbarkub  [category]="othersres"></app-new-navbarkub>
                        
                          </div> -->
                        
                            
                
                    </header>
                </div>
                <div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'SpecialWires'" style="background-color: white !important;">
                    <header>
                        <div class="row" style="display: inline-flex; justify-content: normal; width: 100%;">
                            <div class="col-1">
                                
                                <img src="../../../../../assets/images/specialwires/logo-new.png" alt="Logo Image" style="padding:8px; object-fit: contain; margin-left: 35px;"class="logo-image custom-pointer" height="120" width="300" alt="Logo"
                                [routerLink]="['/']"> 
                            </div>
                                <div class="col-4"  style="margin-left: 28vw; margin-top: 26px;"> 
                                    <div class="inq-menu-st row justify-content-center">
                                        <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                                                data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                                                class="navbar-toggler"><span class="navbar-toggler-icon"></span><span>Menu</span></button>
                                            <div id="navbarNav" class="collapse navbar-collapse">
                                                <ul class="navbar-nav" style="padding:15px;">
                                                    <li class="nav-item" id="home" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                                            href="#/"><p style="font-weight: bold;color:black">HOME</p></a></li>
                                                            <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                                <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important; font-weight: bold;color:black">PRODUCTS</p></a>
                                                                </li>
                                                                <li class="header-cart account-popup1" id="shop" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important">
                                                                    <a href="javascript:;" class="nav-link menu-text"href="#/about-us" ><p style="font-weight: bold;color:black;white-space: nowrap;">ABOUT US</p></a>
                                                                    </li>
                                                            <li class="header-cart account-popup1" id="shop" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important">
                                                                <a href="#/contact-us" class="nav-link menu-text"><p style="font-weight: bold;color:black;white-space: nowrap;">CONTACT US</p></a>
                                                                </li>     
                                                </ul>
                                            </div>
                                        </nav>
                                    </div>
                                    </div>
                            <div class="col-3 header-special"  style="margin-top: 3.4%;margin-right:inherit;">
                                
                                    <ul class="d-flex">
                                        <li class="d-flex header-cart account-popup1" style="margin-right: 20%;
                                        margin-left: 20%;">
                                            <a href="javascript:;"  style="text-decoration: none !important;color: black !important;" (click)="search()">
                                                <i class="fa fa-search" aria-hidden="true"></i>
                                            </a>
                                            <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;margin-top: -30px;">
                                                <div class="row" style="margin: 5px;">
                                                    <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                        <div *ngFor="let category of allCategories">
                                                                            <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                        </div>                                      
                                                                    </div>
                                                                  </div>
                                                                <input type="text" placeholder="Search here."
                                                                    class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                                <div *ngIf="preList.length != 0">
                                                                    <div class="autocomplete-items2">
                                                                        <div *ngFor="let preItem of preList">
                                                                            <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="input-group-btn">
                                                                    <button class="btn btn-solid" >
                                                                        <i class="fa fa-search" (click)="savePreList1()"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                    </form>
                                                    
                                                </div>
                                                
                                        
                                            </div>
                                        </li>

                                        <li class="d-flex" style="margin-right: 25%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                                            <a href="javascript:;"  style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;display: contents;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">
                                                <span class="fa fa-user" style="font-size:25px;margin-right: 6px;">
                                                </span> Login
                                            </a>
                                        </li>
                
                                        <li class="d-flex" style="margin-right:12%;" *ngIf="appService.userLoggedIn">
                                            <div class="dropdown">
                                                <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: #1d1b1a !important;"><span
                                                        class="lnr lnr-user">
                                                        {{ appService.userName.substring(0,18) }}
                                                    </span>
                                                </a>
                                                <div id="dropdown" class="dropdown-menu" style="background-color: white!important;" aria-labelledby="dropdownMenuButton">
                                                    <a class="logout-cutom dropdown-item" routerLink="my-account" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">My Account</a>
                                                    <a class="logout-cutom dropdown-item" routerLink="my-orders" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">My Order</a>
                                                    
                                                    <a class="logout-cutom dropdown-item" (click)="logOut()" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">Logout</a>
                                                </div>
                                            </div>
                                        </li>
                
                                        <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                                            <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: black !important;">
                                                <span class="fa fa-shopping-basket" style="font-size:25px">
                                                    <span style="position: absolute;
                                                    background: #FBCB0C;
                                                    width: 18px;
                                                    height: 18px;
                                                    border-radius: 9px;
                                                    top: -10px;
                                                    right: 9%;" >
                                                   
                                                        <span style="  color: #fff;
                                                        position: absolute;
                                                        left: 0.2rem;
                                                        font-size: 14px;
                                                        font-weight: bold;">{{appService.totalCartItems}}</span>
                                                    </span>
                                                </span>  
                                                 <!-- <span class="menu-title">
                                                    Cart
                                                </span> -->
                                            </a>
                                            <app-mini-cart></app-mini-cart>
                                        </li>
                                    </ul>
                            
                            </div>
                        </div>
                        
                            
                
                    </header>
                </div>
    
    <div class="container-fluid-header-sticky-header1" style="background-color:white;height: 12vw;
                    padding: 2vw;" *ngIf="configs.title === 'SpecialWires'">
                        <header>
                            <div>
                <ul style="display:flex;gap: 0px;">
                    <li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:black" (click)="openNavkub()">&#9776;</span>
                    </li>
                    <li>
                                <img src="../../../../../assets/images/specialwires/logo-new.png" alt="Logo Image" style="width: 34vw;
                                height: 17vw;
                                ;margin-left: 19vw;margin-right:6vw;object-fit: contain;"class="logo-imagekub custom-pointer" alt="Logo"
                                        [routerLink]="['/']"> 
                    </li>
                    <li class="d-flex header-cart account-popup1">
                                            <a href="javascript:;" (click)="search()">
                                                <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: black !important;    font-size: 5vw;
                                                margin-left: 1vw;
                                                margin-top: 2vw;" (click)="opensearchnavkub()"></i>
                                            </a>
                                            <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                                                <div class="row" style="margin: 5px;">
                                                    <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                        <div *ngFor="let category of allCategories">
                                                                            <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                        </div>                                      
                                                                    </div>
                                                                  </div>
                                                            </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="d-flex" style="    margin-left: 2vw;
                                        margin-top: 1.5vw;margin-right: 2vw;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                                            <a href="javascript:;"  style="text-decoration: none !important;color: black !important;">
                                                <span class="fa fa-user" style="font-size:6vw">
                                                </span>
                                            </a>
                                        </li>
                
                                        <li class="d-flex" style="margin-left:3vw;" *ngIf="appService.userLoggedIn">
                                            <div class="dropdown">
                                                <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: black !important;font-size:3vw;margin-top:1vw"><span
                                                        class="lnr lnr-user">
                                                    </span>
                                                    {{ appService.userName.substring(0,4) }}...
                                                    <!-- <p style="white-space: break-spaces;font-size: 2vw;">{{ appService.userName.substring(0,4) }}...</p> -->
                                                </a>
                                                <div id="dropdown" class="dropdown-menu" style="background-color: white !important;" aria-labelledby="dropdownMenuButton">
                                                    <div id="dropdown" class="dropdown-menu show" style=" transform: translate3d(-47px, -20px, 0px) !important;">
                                                    <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: black !important;">My Account</a>
                                                    <a class="logout-cutom dropdown-item" routerLink="my-orders" style="color: black !important;">My Order</a>
                                                    
                                                    <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: black !important;">Logout</a>
                                                </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                                            <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: black !important;">
                                                <span class="fa fa-shopping-basket" style="font-size: 5vw;
                                                margin-top: 2vw;
                                                margin-left: 1vw;">
                                                    <span style="position: absolute;
                                                    background: #FBCB0C;
                                                    width: 5vw;
                                                    height: 4.5vw;
                                                    top: 0vw;
                                                    border-radius: 2vw;" >
                                                   
                                                        <span style="color: #fff;
                                                        position: absolute;
                                                        margin-top: 2px;
                                                        left: 0.4rem;
                                                        font-size: 3vw;
                                                        font-weight: bold;">{{appService.totalCartItems}}</span>
                                                    </span>
                                                </span>  
                                                 <!-- <span class="menu-title">
                                                    Cart
                                                </span> -->
                                            </a>
                                            <app-mini-cart></app-mini-cart>
                                        </li>
                </ul>
                
                            </div>
                            <div id="mySidenav1" class="sidenav" style="background-color:#fff">
                                <a href="javascript:void(0)" style="float:right;font-size:50px;color: black;" class="nav-link menu-text" (click)="closeNavkub11()"><p>&#8592;</p></a>
                                                        <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myfruits1','FRUITS')"><p style="color:black">HOME&#8594;</p></a>
                                
                                <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p style="color:black">PRODUCTS &#8594;</p></a>
                                <a href="javascript:void(0)"  style="color: black;" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p style="color:black">ABOUT US &#8594;</p></a>
                                <a href="javascript:void(0)"  style="color: black;" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p style="color:black">CONTACT US &#8594;</p></a>
                                <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myothers1','OTHERS')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->
                                           
                          
                              </div>
                              <div id="myfruits1" class="sidenav">
                                <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;HOME </span>
                                
                                <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub>
                            
                              </div>
                              <div id="myvegetables1" class="sidenav">
                                <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;PRODUCTS </span>
                                
                                <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub>
                            
                              </div>
                              <div id="myvegetables1" class="sidenav">
                                <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;ABOUT US </span>
                                
                                <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub>
                            
                              </div>
                              <div id="myvegetables1" class="sidenav">
                                <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;CONTACT US </span>
                                
                                <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub>
                            
                              </div>
                              <!-- <div id="myothers1" class="sidenav">
                                <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub1()">&#8592;OTHER DEPARTMENTS </span>
                                
                                <app-new-navbarkub  [category]="othersres"></app-new-navbarkub>
                            
                              </div> -->
                            <div id="searchnav" class="sidenav" style="background-color:#fff">
                                <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closesearchnavkub()"><p>&#8592;</p></a>
                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                <input type="text" placeholder="Search here."
                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                <ul *ngFor="let preItem of preList">
                                    <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">{{preItem.name}}</li>
                                </ul>
                                </form>
                                
                                <!-- <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                                    <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                        <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                            <div *ngFor="let category of allCategories">
                                                                <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                            </div>                                      
                                                        </div>
                                                      </div>
                                                    <input type="text" placeholder="Search here."
                                                        class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                    <div *ngIf="preList.length != 0">
                                                        <div class="autocomplete-items2">
                                                            <div *ngFor="let preItem of preList">
                                                                <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="input-group-btn">
                                                        <button class="btn btn-solid" >
                                                            <i class="fa fa-search" (click)="savePreList1()"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                        </form>
                                        
                                    </div>
                                    
                            
                                </div> -->
        
                            </div>
                            <div id="mySidenav" class="sidenav" style="background-color:#fff">
                                <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closeNavkub1()"><p>&#8592;</p></a>
                                <a class="nav-link menu-text" ng-reflect-router-link="/"
                                                        href="#/" (click)="closeNavkub1()"><p style="color:black">HOME</p></a>
                                                        <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myfruits')"><p>HOME &#8594;</p></a> -->
                                
                                <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsList()"><p style="color:black;">PRODUCTS</p></a>
                                <a href="javascript:void(0)" class="nav-link menu-text" href="#/about-us" (click)="routeTo()"><p style="color:black">ABOUT US</p></a>
                                <a href="javascript:void(0)" class="nav-link menu-text" href="#/contact-us" (click)="routeTo()"><p style="color:black">CONTACT US</p></a>
                                <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myothers')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->
                                           
                          
                              </div>  
                              <div id="myfruits" class="sidenav" style="background-color:#fff">
                                <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">&#8592;HOME </span>
                                
                                <!-- <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub> -->
                            
                              </div>
                              <div id="myvegetables" class="sidenav">
                                <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">PRODUCTS </span>
                                
                                <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->
                            
                              </div>
                              <div id="myvegetables" class="sidenav">
                                <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">ABOUT US </span>
                              </div>
                              <div id="myvegetables" class="sidenav">
                                <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">CONTACT US </span>
                              </div>
                        </header>
                    </div>
                    <div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'Vistas'" style="background-color: white !important;">
                        <header>
                            <div class="row" style="display: inline-flex; justify-content: normal; width: 100%;">
                                <div class="col-1">
                                    
                                    <img src="../../../../../assets/images/vistas/Final Logo.jpg" alt="Logo Image" style=" border: 1px black solid; padding: 8px; object-fit: cover;margin: 15px 0 0 35px; box-shadow: 0 2px 15px #0003, 0 5px 16px #00000030;"class="logo-image custom-pointer" height="110" width="160" alt="Logo"
                                    [routerLink]="['/']"> 
                                </div>
                                    <div class="col-4"  style="margin-left: 28vw; margin-top: 26px;"> 
                                        <div class="inq-menu-st row justify-content-center">
                                            <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                                                    data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                                                    class="navbar-toggler"><span class="navbar-toggler-icon"></span><span>Menu</span></button>
                                                <div id="navbarNav" class="collapse navbar-collapse">
                                                    <ul class="navbar-nav" style="padding:15px;">
                                                        <li class="nav-item" id="home" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                                            href="#/"><p style="font-weight: bold;color:black">HOME</p></a></li>
                                                                <li class="header-cart account-popup1" id="shop" style="margin-left: 0.1rem !important">
                                                                    <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important; font-weight: bold;color:black">PRODUCTS</p></a>
                                                                    </li>
                                                                    <li class="header-cart account-popup1" id="shop" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important">
                                                                        <a href="javascript:;" class="nav-link menu-text"href="#/about-us" ><p style="font-weight: bold;color:black;white-space: nowrap;">ABOUT US</p></a>
                                                                        </li>
                                                                <li class="header-cart account-popup1" id="shop" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;margin-left: 0.1rem !important">
                                                                    <a href="#/contact-us" class="nav-link menu-text"><p style="font-weight: bold;color:black;white-space: nowrap;">CONTACT US</p></a>
                                                                    </li>     
                                                    </ul>
                                                </div>
                                            </nav>
                                        </div>
                                        </div>
                                <div class="col-3 header-special"  style="margin-top: 3.4%;margin-right:inherit;">
                                    
                                        <ul class="d-flex">
                                            <li class="d-flex header-cart account-popup1" style="margin-right: 20%;
                                            margin-left: 20%;">
                                                <a href="javascript:;"  style="text-decoration: none !important;color: black !important;" (click)="search()">
                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                </a>
                                                <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;margin-top: -30px;">
                                                    <div class="row" style="margin: 5px;">
                                                        <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                            <div *ngFor="let category of allCategories">
                                                                                <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                            </div>                                      
                                                                        </div>
                                                                      </div>
                                                                    <input type="text" placeholder="Search here."
                                                                        class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                                    <div *ngIf="preList.length != 0">
                                                                        <div class="autocomplete-items2">
                                                                            <div *ngFor="let preItem of preList">
                                                                                <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="input-group-btn">
                                                                        <button class="btn btn-solid" >
                                                                            <i class="fa fa-search" (click)="savePreList1()"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                        </form>
                                                        
                                                    </div>
                                                    
                                            
                                                </div>
                                            </li>
    
                                            <li class="d-flex" style="margin-right: 25%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                                                <a href="javascript:;"  style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;display: contents;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">
                                                    <span class="fa fa-user" style="font-size:25px;margin-right: 6px;">
                                                    </span> Login
                                                </a>
                                            </li>
                    
                                            <li class="d-flex" style="margin-right:12%;" *ngIf="appService.userLoggedIn">
                                                <div class="dropdown">
                                                    <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: #1d1b1a !important;"><span
                                                            class="lnr lnr-user">
                                                            {{ appService.userName.substring(0,18) }}
                                                        </span>
                                                    </a>
                                                    <div id="dropdown" class="dropdown-menu" style="background-color: white!important;" aria-labelledby="dropdownMenuButton">
                                                        <a class="logout-cutom dropdown-item" routerLink="my-account" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">My Account</a>
                                                        <a class="logout-cutom dropdown-item" routerLink="my-orders" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">My Order</a>
                                                        
                                                        <a class="logout-cutom dropdown-item" (click)="logOut()" style="text-shadow: none;font-family: 'proxima-regular','sans-serif'!important;color: #1d1b1a!important;text-align: center; font-size: 15px;font-weight: bold;">Logout</a>
                                                    </div>
                                                </div>
                                            </li>
                    
                                            <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                                                <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: black !important;">
                                                    <span class="fa fa-shopping-basket" style="font-size:25px">
                                                        <span style="position: absolute;
                                                        background: #FBCB0C;
                                                        width: 18px;
                                                        height: 18px;
                                                        border-radius: 9px;
                                                        top: -10px;
                                                        right: 9%;" >
                                                       
                                                            <span style="  color: #fff;
                                                            position: absolute;
                                                            left: 0.2rem;
                                                            font-size: 14px;
                                                            font-weight: bold;">{{appService.totalCartItems}}</span>
                                                        </span>
                                                    </span>  
                                                     <!-- <span class="menu-title">
                                                        Cart
                                                    </span> -->
                                                </a>
                                                <app-mini-cart></app-mini-cart>
                                            </li>
                                        </ul>
                                
                                </div>
                            </div>
                            
                                
                    
                        </header>
                    </div>
        
        <div class="container-fluid-header-sticky-header1" style="background-color:white;height: 20vw;
                        padding: 2vw;" *ngIf="configs.title === 'Vistas'">
                            <header>
                                <div>
                    <ul style="display:flex;gap: 0px;margin-top: 9px;">
                        <li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:black" (click)="openNavkub()">&#9776;</span>
                        </li>
                        <li>
                                    <img src="../../../../../assets/images/vistas/Final Logo.jpg" alt="Logo Image" style="width: 34vw;
                                    height: 17vw;
                                    ;margin-left: 19vw;margin-right:6vw;object-fit: contain;"class="logo-imagekub custom-pointer" alt="Logo"
                                            [routerLink]="['/']"> 
                        </li>
                        <li class="d-flex header-cart account-popup1">
                                                <a href="javascript:;" (click)="search()">
                                                    <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: black !important;    font-size: 5vw;
                                                    margin-left: 1vw;
                                                    margin-top: 2vw;" (click)="opensearchnavkub()"></i>
                                                </a>
                                                <!-- <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                                                    <div class="row" style="margin: 5px;">
                                                        <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                            <div *ngFor="let category of allCategories">
                                                                                <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                            </div>                                      
                                                                        </div>
                                                                      </div>
                                                                </div>
                                                        </form>
                                                    </div>
                                                </div> -->
                                            </li>
                                            <li class="d-flex" style="    margin-left: 2vw;
                                            margin-top: 1.5vw;margin-right: 2vw;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                                                <a href="javascript:;"  style="text-decoration: none !important;color: black !important;">
                                                    <span class="fa fa-user" style="font-size:6vw">
                                                    </span>
                                                </a>
                                            </li>
                    
                                            <li class="d-flex" style="margin-left:3vw;" *ngIf="appService.userLoggedIn">
                                                <div class="dropdown">
                                                    <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: black !important;font-size:3vw;margin-top:1vw"><span
                                                            class="lnr lnr-user">
                                                        </span>
                                                        {{ appService.userName.substring(0,4) }}...
                                                        <!-- <p style="white-space: break-spaces;font-size: 2vw;">{{ appService.userName.substring(0,4) }}...</p> -->
                                                    </a>
                                                    <div id="dropdown" class="dropdown-menu" style="background-color: white !important;" aria-labelledby="dropdownMenuButton">
                                                        <div id="dropdown" class="dropdown-menu show" style=" transform: translate3d(-47px, -20px, 0px) !important;">
                                                        <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: black !important;">My Account</a>
                                                        <a class="logout-cutom dropdown-item" routerLink="my-orders" style="color: black !important;">My Order</a>
                                                        
                                                        <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: black !important;">Logout</a>
                                                    </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                                                <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: black !important;">
                                                    <span class="fa fa-shopping-basket" style="font-size: 5vw;
                                                    margin-top: 2vw;
                                                    margin-left: 1vw;">
                                                        <span style="position: absolute;
                                                        background: #FBCB0C;
                                                        width: 5vw;
                                                        height: 4.5vw;
                                                        top: 0vw;
                                                        border-radius: 2vw;" >
                                                       
                                                            <span style="color: #fff;
                                                            position: absolute;
                                                            margin-top: 2px;
                                                            left: 0.4rem;
                                                            font-size: 3vw;
                                                            font-weight: bold;">{{appService.totalCartItems}}</span>
                                                        </span>
                                                    </span>  
                                                     <!-- <span class="menu-title">
                                                        Cart
                                                    </span> -->
                                                </a>
                                                <app-mini-cart></app-mini-cart>
                                            </li>
                    </ul>
                    
                                </div>
                                <div id="mySidenav1" class="sidenav" style="background-color:#fff">
                                    <a href="javascript:void(0)" style="float:right;font-size:50px;color: black;" class="nav-link menu-text" (click)="closeNavkub11()"><p>&#8592;</p></a>
                                                            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myfruits1','FRUITS')"><p style="color:black">HOME&#8594;</p></a>
                                    
                                    <a href="javascript:void(0)" style="color: black;" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p style="color:black">PRODUCTS &#8594;</p></a>
                                    <a href="javascript:void(0)"  style="color: black;" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p style="color:black">ABOUT US &#8594;</p></a>
                                    <a href="javascript:void(0)"  style="color: black;" class="nav-link menu-text" (click)="productsListkubendran2('myvegetables1','VEGETABLES')"><p style="color:black">CONTACT US &#8594;</p></a>
                                    <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myothers1','OTHERS')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->
                                               
                              
                                  </div>
                                  <div id="myfruits1" class="sidenav">
                                    <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;HOME </span>
                                    
                                    <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub>
                                
                                  </div>
                                  <div id="myvegetables1" class="sidenav">
                                    <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;PRODUCTS </span>
                                    
                                    <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub>
                                
                                  </div>
                                  <div id="myvegetables1" class="sidenav">
                                    <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;ABOUT US </span>
                                    
                                    <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub>
                                
                                  </div>
                                  <div id="myvegetables1" class="sidenav">
                                    <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub1()">&#8592;CONTACT US </span>
                                    
                                    <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub>
                                
                                  </div>
                                  <!-- <div id="myothers1" class="sidenav">
                                    <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNavkub1()">&#8592;OTHER DEPARTMENTS </span>
                                    
                                    <app-new-navbarkub  [category]="othersres"></app-new-navbarkub>
                                
                                  </div> -->
                                <div id="searchnav" class="sidenav" style="background-color:#fff">
                                    <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closesearchnavkub()"><p>&#8592;</p></a>
                                    <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                    <input type="text" placeholder="Search here."
                                    class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                    <ul *ngFor="let preItem of preList">
                                        <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">{{preItem.name}}</li>
                                    </ul>
                                    </form>

                                </div>
                                <div id="mySidenav" class="sidenav" style="background-color:#fff">
                                    <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closeNavkub1()"><p>&#8592;</p></a>
                                    <a class="nav-link menu-text" ng-reflect-router-link="/"
                                                            href="#/" (click)="closeNavkub1()"><p style="color:black">HOME</p></a>
                                    
                                    <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsList()"><p style="color:black;">PRODUCTS</p></a>
                                    <a href="javascript:void(0)" class="nav-link menu-text" href="#/about-us" (click)="routeTo()"><p style="color:black">ABOUT US</p></a>
                                    <a href="javascript:void(0)" class="nav-link menu-text" href="#/contact-us" (click)="routeTo()"><p style="color:black">CONTACT US</p></a>
                                    <!-- <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myothers')"><p>OTHER DEPARTMENTS &#8594;</p></a> -->
                                               
                              
                                  </div>  
                                  <div id="myfruits" class="sidenav" style="background-color:#fff">
                                    <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">&#8592;HOME </span>
                                    
                                    <!-- <app-new-navbarkub  [category]="fruitsres"></app-new-navbarkub> -->
                                
                                  </div>
                                  <div id="myvegetables" class="sidenav">
                                    <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">PRODUCTS </span>
                                    
                                    <!-- <app-new-navbarkub  [category]="vegetablesres"></app-new-navbarkub> -->
                                
                                  </div>
                                  <div id="myvegetables" class="sidenav">
                                    <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">ABOUT US </span>
                                  </div>
                                  <div id="myvegetables" class="sidenav">
                                    <span style="font-size: 5vw;cursor:pointer;color:black" (click)="openNavkub()">CONTACT US </span>
                                  </div>
                            </header>
                        </div>
    <!-- <div class="earthy-worthy-section earthy-worthy-menu"> -->
        <div class="container-fluid header sticky-header vimpro-header" *ngIf="configs.title != 'Aiema' && configs.title != 'Cle' && configs.title != 'LinenRaj' && configs.title != 'BestGifts' && configs.title != 'AvonSeals' && configs.title != 'SpecialWires' && configs.title != 'Vistas'">
            <header>
                <div class="inq-htop-st inq-row" id="banner" *ngIf="promotionBanner != '' && configs.title === 'Deera'">
                    <div class="text-center">
                        <p>
                            <span [innerHtml]="promotionBanner"></span>
                            <!-- <span>
                                <a href="#"><strong> More Details</strong></a>
                            </span> -->
                        </p>
                        <!-- <p>Get <strong>UPTO 40% OFF</strong> on your 1st order <span><a href="#"><strong> More Details</strong>
                                </a></span></p> -->
                        <span style="cursor: pointer;"><i class="fa fa-close" (click)='hideBanner()'></i></span>
                    </div>
                </div>
                <div class="flex-container" *ngIf="configs.title != 'BestGifts'&& configs.title != 'Kubendran'">
                    <img [src]="logoImage" *ngIf="configs.title != 'Karupattiraja'" alt="Logo Image" class="logo-image custom-pointer mob-logo" height="50" alt="Logo" [routerLink]="['/']">
                    <img [src]="logoImage" *ngIf="configs.title == 'Karupattiraja'" alt="Logo Image" class="logo-karupatti custom-pointer mob-logo" height="70" alt="Logo" style="margin-left:40px;" [routerLink]="['/']">
                   
                    <div class="header-row-one">
                        <div class="search-bar-group" style="margin-right: 10px !important; margin-top: 5px;">
                            <form [formGroup]="searchForm" class="search-bar">
                                <div class="d-flex justify-content-between search-input">


                                    <!-- <p>Browse all <br><span>Categories</span></p> -->
                                    <div class="input-group">
                                        <div class="input-group-prepend" *ngIf="configs.title != 'Vimpro'" >
                                            <button class="btn search-drop dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="closePrelist()" style="font-size: 14px !important;">All Categories</button>
                                            <div class="dropdown-menu autocomplete-items">
                                                <div *ngFor="let category of allCategories">
                                                    <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                </div>                                      
                                            </div>
                                          </div>
                                        

                                        <div  id='vimpro_cate' class="input-group-prepend desktop-only"  *ngIf="configs.title == 'Vimpro'" style="    position: fixed!important;">
                                            <button class="btn search-drop dropdown-toggle v_cate_top"  style="min-height: 42px;"  type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="closePrelist()" >Top Categories</button>
                                            <div class="dropdown-menu autocomplete-items vim_dropdown" style="background-color: rgb(37 84 199);;width: 15rem!important">
                                                <div *ngFor="let category of allCategories">
                                            
                                                    <a  *ngIf="category.name.toLowerCase().includes('pp flute board')" class="dropdown-item vimpro_drop1" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;color: white!important;">PP Flute Board</a>
                                                    <a *ngIf="!category.name.toLowerCase().includes('pp flute board')" class="dropdown-item vimpro_drop1" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;color: white!important;">{{category.name | titlecase}}</a>

                                                </div>  
                                             
                                            </div>
                                          </div>
                                          
                                        <input type="text" placeholder="Search here.." *ngIf="configs.title !== 'Vimpro'"
                                            class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                        <input type="text" placeholder="Search our catalogue.." *ngIf="configs.title === 'Vimpro'" style="border-radius: 50px 0px 0px 50px !important;"
                                            class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">

                                        <div *ngIf=" configs.title != 'Vimpro' && preList.length != 0">
                                            <div class="autocomplete-items1">
                                                <div *ngFor="let preItem of preList">
                                                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf=" configs.title == 'Vimpro' && preList.length != 0">
                                            <div class="autocomplete-items3">
                                                <div *ngFor="let preItem of preList">
                                                    <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group-btn">
                                            <button class="btn btn-solid vimpro-searchbtn" >
                                                <i class="fa fa-search" (click)="savePreList1()"></i>
                                            </button>
                                        </div> 
                                    </div>
                                    
                                </div>
                            </form>   
                            <ul class="account mobile-cart" style="margin-top:-40px;margin-bottom:0px !important;margin-left:10px">            
                                <li class="header-cart account-popup" style="margin-right:-28px;">                                        
                                    <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;">
                                        <span class="fa fa-shopping-cart white_vimpro" style="font-size:25px;">
                                            <span class="count b-radius">
                                                <span class="count-number f-increse">{{appService.totalCartItems}}</span>
                                            </span>
                                        </span>  
                                         <span class="menu-title white_vimpro" >
                                            Cart
                                        </span>
                                    </a>
                                    <!-- <h3 *ngIf=" configs.title == 'Vimpro'"class="sure_we_can">s</h3>  -->

                                        <!-- <h3  *ngIf="configs.title ==='Vimpro'" class="sure_we">sure we can</h3> -->
                                        <h3 *ngIf="configs.title ==='Vimpro'"  class="sure_we"><span style="font-size:26px;font-family: cursive;">s</span>ure we can</h3>

                                    <app-mini-cart></app-mini-cart>
                                </li>
                            </ul>                         
                        </div>
                        <div class="account-section">
                            <ul class="account">
                                
                                <li *ngIf="!appService.userLoggedIn" (click)="logIn()" style="margin-right:10px !important">
                                    <a  *ngIf="configs.title!='Vimpro'" href="javascript:;" >
                                        <span class="fa fa-user" style="font-size:20px !important">
                                        </span> Login
                                    </a>
                                    <div class="disktop-only">
                                        <a  *ngIf="configs.title=='Vimpro'" href="javascript:;" style="color: #ffffff!important" >
                                            <span class="fa fa-user" style="color: #ffffff">
                                            </span> Login
                                        </a>
                                    </div>
                                   
                                    <div class="mobile-only">
                                        <a  *ngIf="configs.title=='Vimpro'" href="javascript:;" style="color: #0025ab!important;top: 18px; position: absolute;font-size: 15px;" >
                                            <span class="fa fa-user" style="font-size: 21px !important;color: #0025ab!important;">
                                            </span> Login
                                        </a>
                                    </div>
                                </li>
                                
                                
                                
                                <li *ngIf="appService.userLoggedIn">
                                    <div class="dropdown">
                                        <a  *ngIf="configs.title!='Vimpro'"  class="menu-title dropdown-toggle " type="button" id="dropdownMenuButton"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span
                                                class="lnr lnr-user loginbut">
                                            </span>
                                            {{ appService.userName }}
                                        </a>
                                        <a  *ngIf="configs.title=='Vimpro'"  class="menu-title dropdown-toggle loginicon" type="button" id="dropdownMenuButton"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span
                                                class="lnr lnr-user loginbut">
                                            </span>
                                            {{ appService.userName }}
                                        </a>
                                        <div id="dropdown" class="dropdown-menu l-5px" aria-labelledby="dropdownMenuButton">
                                            <a class="logout-cutom dropdown-item" routerLink="my-account">My Account</a>
                                            <a *ngIf= "configs.title !== 'BombayHardware' && configs.title !== 'AvonSeals' && configs.title != 'SpecialWires' && configs.title != 'Vistas' && configs.title != 'Cle' && configs.title != 'LinenRaj'" class="logout-cutom dropdown-item" routerLink="my-orders">My Order</a>
                                            <a *ngIf= "configs.title === 'BombayHardware' || configs.title === 'AvonSeals' || configs.title === 'SpecialWires' || configs.title === 'Vistas' || configs.title === 'Cle' || configs.title === 'LinenRaj'" class="logout-cutom dropdown-item" routerLink="enquiry-status">My Enquiry</a>

                                            <a class="logout-cutom dropdown-item" (click)="logOut()">Logout</a>
                                        </div>
                                    </div> 
                                </li>
        
                                <li class="header-cart account-popup desktop-only">     
                                    <a *ngIf="configs.title=='Vimpro'"  href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: white!important;">
                                        <span class="fa fa-shopping-cart" style="font-size:25px">
                                            <span class="count">
                                                <span class="count-number">{{appService.totalCartItems}}</span>
                                            </span>
                                        </span>  
                                         <span class="menu-title" style="color: white!important;">
                                            Cart
                                        </span>
                                    </a>                                   
                                    <a  *ngIf="configs.title!='Vimpro'" href="javascript:;" routerLink="/cart" style="text-decoration: none !important;">
                                        <span class="fa fa-shopping-cart" style="font-size:25px">
                                            <span class="count">
                                                <span class="count-number">{{appService.totalCartItems}}</span>
                                            </span>
                                        </span>  
                                         <span class="menu-title">
                                            Cart
                                        </span>
                                    </a>
                                    <app-mini-cart></app-mini-cart>
                                </li>
                            </ul>
                            <div *ngIf="configs.title=='Vimpro'" (click)="submitEnquiryForVimpro()" class="reach_us_out"><i class="fa fa-envelope" aria-hidden="true"></i><span>&nbsp;Reach Us Out Here</span></div>
                            <div *ngIf="configs.title=='Vimpro'" (click)="submitEnquiryForVimpro()" class="reach_us"><i class="fa fa-envelope" aria-hidden="true"></i><span>&nbsp;Reach Us</span></div>
                        </div>
        
                    </div>
        
                </div>
        
            </header>
        </div>
        <div class="container-fluid navbar-row vimpro-nav" *ngIf="configs.title != 'Aiema' && configs.title != 'Cle' && configs.title != 'LinenRaj' && configs.title != 'BestGifts' && configs.title != 'Kubendran' && configs.title != 'SpecialWires' && configs.title != 'Vistas'">
            <app-menu></app-menu>
        </div>
    <!-- </div> -->
    <div *ngIf="configs.title === 'Aiema'">
        <div class="earthy-worthy-section earthy-worthy-menu">
            <div class="container-fluid header sticky-header" >
                <header>
                    <div class="flex-container">
                        <img [src]="logoImage" alt="Logo Image" class="custom-pointer aiema-logo" height="50" alt="Logo" style="width: 72px!important; height: 63px!important;"
                                    [routerLink]="['/']">
                        <div class="header-row-one" style="width: 100%;">
                            <div class="search-bar-group" style="margin-right: 10px !important;display:contents;z-index:10; width:100%;margin-top: 5px;">
                                <form [formGroup]="searchForm" class="search-bar" style=" width:100%;margin-top:15px">
                                    <div class="d-flex justify-content-between search-input-aiema" style="margin-right:25px;">
                                        <!-- <p>Browse all <br><span>Categories</span></p> -->
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <button class="btn search-drop dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="closePrelist()" style="font-size: 14px !important;">All Categories</button>
                                                <div class="dropdown-menu autocomplete-items">
                                                    <div *ngFor="let category of allCategories">
                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                    </div>                                      
                                                </div>
                                              </div>
                                            <input type="text" placeholder="Search here.."
                                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                            <div *ngIf="preList.length != 0">
                                                <div class="autocomplete-items1">
                                                    <div *ngFor="let preItem of preList">
                                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group-btn">
                                                <button class="btn btn-solid" >
                                                    <i class="fa fa-search" (click)="savePreList1()"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="account-section">
                                <ul class="account" style="margin-right:0px">
                                    <li *ngIf="!appService.userLoggedIn" style="margin-left:20px !important">
                                        <div class="dropdown">
                                            <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton1"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;font-size: 14px;font-weight: 500;"><span
                                                class="fa fa-user" style="font-size:25px; color: rgb(102, 102, 102);">
                                                </span>
                                                User/Member Login
                                            </a>
                                            <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <a class="logout-cutom dropdown-item" routerLink="login-new">User Login/Register</a>
                                                <a class="logout-cutom dropdown-item" routerLink="member-register">Member Register</a>
                                            </div>
                                        </div>
                                    </li>
            
                                    <li *ngIf="appService.userLoggedIn" style="margin-left:20px !important">
                                        <div class="dropdown">
                                            <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;"><span
                                                    class="lnr lnr-user">
                                                </span>
                                                {{ appService.userName }}
                                            </a>
                                            <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="logout-cutom dropdown-item" routerLink="my-account">My Account</a>
                                                <!-- <a class="logout-cutom dropdown-item" routerLink="my-orders">My Order</a> -->
                                                <a class="logout-cutom dropdown-item" (click)="logOut()">Logout</a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <!-- <div class="float-right">
                                <button data-toggle="tooltip" data-placement="top" title="Upcoming Feature" class="btn btn-outline-warning shadow-sm" style="border-radius: 30px !important; font-size: 14px; padding: 5px 25px;font-weight: 500;" disabled>Quick Enquiry</button>
                            </div> -->
                        </div>
            
                    </div>
            
                </header>
            </div>
            <div class="container-fluid navbar-row" style="padding-left: 0px !important;padding-right: 0px !important;">
                <app-menu></app-menu>
            </div>
        </div>
    </div>

    <div *ngIf="configs.title === 'Cle'" class="avonHome1">
        <div class="container-fluid-header-sticky-header">
            <div class="container-fluid header sticky-header cle-header" >
                 <span class="container-fluid header sticky-header cle-header" style="display: flex;
                 justify-content: center;font-weight:700">E-commerce portal of Indian Footwear(Leather And Non Leather) Products</span>
                <header>
                    <div class="flex-container">
                        <img [src]="logoImage" alt="Logo Image" class="custom-pointer cle-logo" height="50" alt="Logo" style="width: 72px!important; height: 100px!important;"
                                    [routerLink]="['/']">
                                    <div style="margin-left: 7px;">
                                        <span style="font-weight: bold;font-size: 15px;">Council For Leather Exports (CLE)</span>
                                        <div>
                                            <span style="font-weight: bold;font-size: 14px;">चर्म निर्यात परिषद</span>
                                        </div>
                                        <div>
                                            <span style="font-size: 12px;">(Sponsored by Ministry of Commerce &amp; Industry, Government of India)</span>
                                        </div>
                                    </div>   
                        <div class="header-row-one" style="width: 100%;">
                            <div class="container-fluid" style="padding-left: 0px !important;padding-right: 0px !important;">
                                <div class="inq-menu-st row ">
                                    <!-- <div class="inq-menu-st row justify-content-center"> -->
                                    <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                                            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                                            class="navbar-toggler"><span class="navbar-toggler-icon"></span><span>Menu</span></button>
                                        <div id="navbarNav" class="collapse navbar-collapse">
                                            <ul class="navbar-nav" style="padding-top:20px">
                                                <li class="nav-item cle-homenav" id="home"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                                        href="#/" style="width: max-content!important;"><p style="font-size: 15px; font-weight: 500; color: black;">HOME</p></a></li>
                                                        <li class="header-cart account-popup1" id="shop" style="width: max-content!important;cursor: pointer;">
                                                            <a (click)="productsLists()" class="nav-link menu-text"><p style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">COMPANIES</p></a>
                                                            </li>
                                                        <li class="header-cart account-popup1" id="shop" style="width: max-content!important;">
                                                            <a href="javascript:;" class="nav-link menu-text" (click)="productsList()" style="display: flex;"><p style="font-size: 15px; font-weight: 500; color: black;">CATEGORY</p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down" style="color: black;"><polyline points="6 9 12 15 18 9"></polyline></svg></a>
                                                            <app-new-navbar [category]="fruits"></app-new-navbar>
                                                            </li>
                                                            <!-- <li class="header-cart account-popup1" id="shop" style="width: max-content!important;"> -->
                                                                <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p>BESTGIFTS</p></a> -->
                                                                <!-- <app-new-navbar [category]="vegetables"></app-new-navbar> -->
                                                                <!-- </li> -->
                                                <!-- <li class="header-cart account-popup1" id="shop">
                                                    <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">SHOP</a>
                                                     <app-new-navbar></app-new-navbar>
                                                    </li> -->
                                                    <!-- <li class="header-cart account-popup1" id="shop" style="width: max-content!important;"> -->
                                                        <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p>VALENTINE SPECIAL</p></a> -->
                                                         <!-- <app-new-navbar [category]="others"></app-new-navbar> -->
                                                        <!-- </li> -->
                                                        <li class="header-cart account-popup1" id="shop" style="width: max-content!important;">
                                                            <a href="#/investor" class="nav-link menu-text"><p style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">INVESTOR ENQUIRY</p></a>
                                                            </li>
                                                        <li class="header-cart account-popup1" id="shop" style="width: max-content!important;">
                                                            <a href="#/about-us" class="nav-link menu-text"><p style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">ABOUT US</p></a>
                                                            </li>
                                                            <li class="header-cart account-popup1" id="shop" style="width: max-content!important;">
                                                                <a href="#/contact-us" class="nav-link menu-text" ><p style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">CONTACT US</p></a>
                                                                </li>
                                                        
                                                <!-- <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                                                        href="#/contact-us">CONTACT US</a></li> -->
                                                        
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <div class="col-3" style="margin-top: 2.5%; padding-right: 0px; padding-left: 0px;">
                
                                <ul class="d-flex cle-headericon">
                                    <div style="font-weight: 500!important;">Search</div>
                                    <li class="d-flex header-cart account-popup1" style="margin-right: 9%;
                                    margin-left: 2%;">
                                    
                                        <a href="javascript:;"  style="text-decoration: none !important;color:#0a0a0a !important;" (click)="search()">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                        </a>
                                        
                                        <div class="min-cart-product-wrapper popup-content2 " style="height:auto;overflow-y:visible!important;">
                                            <div class="row" style="border-bottom : 2px;margin: 5px;">
                                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                    <div *ngFor="let category of allCategories">
                                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                    </div>                                      
                                                                </div>
                                                              </div>
                                                            <input type="text" placeholder="Search here."
                                                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                            <div *ngIf="preList.length != 0">
                                                                <!-- <div class="autocomplete-items2"> -->
                                                                    <div class="autocomplete-items2">
                                                                    <div *ngFor="let preItem of preList">
                                                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="input-group-btn">
                                                                <button class="btn btn-solid" >
                                                                    <i class="fa fa-search" (click)="savePreList1()"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                </form>
                                                
                                            </div>
                                            
                                    
                                        </div>
                                    </li>
                                    <!-- <li>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search">
                                        <div class="input-group-append">
                                          <button class="btn btn-secondary" style="border-radius: 0px!important;" type="button">
                                            <i class="fa fa-search"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </li> -->
                                    
                                    <li class="d-flex" style="margin-right: 19%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                                        <span class="menu-title" style="cursor: pointer;">Login</span>  <a href="javascript:;"  style="text-decoration: none !important;color: #0a0a0a !important; font-size: 13px;">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="29" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                        </a>
                                    </li> 
            
                                    <li class="d-flex" *ngIf="appService.userLoggedIn" style="margin-left: 0px;">
                                        <div class="dropdown">
                                            <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: #3C57A6 !important;"><span
                                                    class="lnr lnr-user">
                                                </span>
                                                {{ appService.userName }}
                                            </a>
                                            <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #0a0a0a !important;">My Account</a>
                                                <a *ngIf= "configs.title !== 'Cle' && configs.title != 'LinenRaj'" class="logout-cutom dropdown-item" routerLink="my-orders" style="color: #0a0a0a!important;">My Order</a>
                                                <a *ngIf= "configs.title === 'Cle' || configs.title === 'LinenRaj'" class="logout-cutom dropdown-item" routerLink="enquiry-status" style="color: #0a0a0a!important;">My Enquiry</a>
                                                <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: #0a0a0a!important;">Logout</a>
                                            </div>
                                        </div>
                                    </li>
            
                                    <li class="header-cart account-popup d-flex" style="position: relative;">   
                                        <span class="menu-title" style="margin-right: 2%;cursor: pointer;
                                        ">Cart</span>                                     
                                        <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: #0a0a0a!important;">
                                            <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>
                                                <span style="position: absolute;
                                                
                                                width: 18px;
                                                height: 18px;
                                                border-radius: 9px;
                                                top: -10px;
                                                right: 9%;" >
                                               
                                                    <span style="  color: #0b0b0b;
                                                    position: absolute;
                                                    left: 1.2rem;
                                                    font-size: 15px;
                                                    font-weight: bold;">{{appService.totalCartItems}}</span>
                                                </span>
                                            </span>  
                                             <!-- <span class="menu-title">
                                                Cart
                                            </span> -->
                                        </a>
                                        
                                        <app-mini-cart></app-mini-cart>
                                    </li>
                                </ul>
                        
                        </div>
                            <!-- <div class="float-right">
                                <button data-toggle="tooltip" data-placement="top" title="Upcoming Feature" class="btn btn-outline-warning shadow-sm" style="border-radius: 30px !important; font-size: 14px; padding: 5px 25px;font-weight: 500;" disabled>Quick Enquiry</button>
                            </div> -->
                        </div>
                        
                    </div>
            
                </header>
            </div>
            
        </div>
    </div>
       
    <div *ngIf="configs.title === 'Cle'" class="avonHome2">
        <div class="container-fluid-header-sticky-header">
            <div class="container-fluid header sticky-header cle-header" >
                 <span class="container-fluid header sticky-header cle-header" style="display: flex;
                 justify-content: center;font-weight:700">E-commerce portal of Indian Footwear(Leather And Non Leather) Products</span>
                <header>
                    <div class="flex-container">
                        <img [src]="logoImage" alt="Logo Image" class="custom-pointer cle-logo" height="50" alt="Logo" style="width: 72px!important; height: 100px!important;"
                                    [routerLink]="['/']">
                                    <div style="margin-left: 7px;width: 20%;">
                                        <span style="font-weight: bold;font-size: 15px;">Council For Leather Exports (CLE)</span>
                                        <div>
                                            <span style="font-weight: bold;font-size: 14px;">चर्म निर्यात परिषद</span>
                                        </div>
                                        <div>
                                            <span style="font-size: 12px;">(Sponsored by Ministry of Commerce &amp; Industry, Government of India)</span>
                                        </div>
                                    </div>   
                        <div class="header-row-one" style="width: 72%;">
                            <div class="container-fluid" style="padding-left: 0px !important;padding-right: 0px !important;">
                                <div class="inq-menu-st row ">
                                    <!-- <div class="inq-menu-st row justify-content-center"> -->
                                    <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                                            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                                            class="navbar-toggler"><span class="navbar-toggler-icon"></span><span>Menu</span></button>
                                        <div id="navbarNav" class="collapse navbar-collapse">
                                            <ul class="navbar-nav" style="padding-top:20px">
                                                <li class="nav-item cle-homenav1" id="home"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                                        href="#/" style="width: max-content!important;"><p style="font-size: 15px; font-weight: 500; color: black;">HOME</p></a></li>
                                                        <li class="header-cart account-popup1" id="shop" style="width: max-content!important;cursor: pointer;">
                                                            <a (click)="productsLists()" class="nav-link menu-text"><p style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">COMPANIES</p></a>
                                                            </li>
                                                        <li class="header-cart account-popup1 cle-homenav1" id="shop" style="width: max-content!important;">
                                                            <a href="javascript:;" class="nav-link menu-text" (click)="productsList()" style="display: flex;"><p style="font-size: 15px; font-weight: 500; color: black;">CATEGORY</p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down" style="color: black;"><polyline points="6 9 12 15 18 9"></polyline></svg></a>
                                                            <app-new-navbar [category]="fruits"></app-new-navbar>
                                                            </li>
                                                            <!-- <li class="header-cart account-popup1" id="shop" style="width: max-content!important;"> -->
                                                                <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p>BESTGIFTS</p></a> -->
                                                                <!-- <app-new-navbar [category]="vegetables"></app-new-navbar> -->
                                                                <!-- </li> -->
                                                <!-- <li class="header-cart account-popup1" id="shop">
                                                    <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">SHOP</a>
                                                     <app-new-navbar></app-new-navbar>
                                                    </li> -->
                                                    <!-- <li class="header-cart account-popup1" id="shop" style="width: max-content!important;"> -->
                                                        <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p>VALENTINE SPECIAL</p></a> -->
                                                         <!-- <app-new-navbar [category]="others"></app-new-navbar> -->
                                                        <!-- </li> -->
                                                        <li class="header-cart account-popup1 cle-homenav1" id="shop" style="width: max-content!important;">
                                                            <a href="#/investor" class="nav-link menu-text"><p style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">INVESTOR ENQUIRY</p></a>
                                                            </li>
                                                        <li class="header-cart account-popup1 cle-homenav1" id="shop" style="width: max-content!important;">
                                                            <a href="#/about-us" class="nav-link menu-text"><p style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">ABOUT US</p></a>
                                                            </li>
                                                            <li class="header-cart account-popup1 cle-homenav1" id="shop" style="width: max-content!important;">
                                                                <a href="#/contact-us" class="nav-link menu-text" ><p style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">CONTACT US</p></a>
                                                                </li>
                                                        
                                                <!-- <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                                                        href="#/contact-us">CONTACT US</a></li> -->
                                                        
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <div class="col-3" style="margin-top: 2.5%; padding-right: 0px; padding-left: 0px;">
                
                                <ul class="d-flex cle-headericon">
                                    <div style="font-weight: 500!important;">Search</div>
                                    <li class="d-flex header-cart account-popup1" style="margin-right: 9%;
                                    margin-left: 2%;">
                                    
                                        <a href="javascript:;"  style="text-decoration: none !important;color:#0a0a0a !important;" (click)="search()">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                        </a>
                                        
                                        <div class="min-cart-product-wrapper popup-content2 " style="height:auto;overflow-y:visible!important;">
                                            <div class="row" style="border-bottom : 2px;margin: 5px;">
                                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                    <div *ngFor="let category of allCategories">
                                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                    </div>                                      
                                                                </div>
                                                              </div>
                                                            <input type="text" placeholder="Search here."
                                                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                            <div *ngIf="preList.length != 0">
                                                                <!-- <div class="autocomplete-items2"> -->
                                                                    <div class="autocomplete-items2">
                                                                    <div *ngFor="let preItem of preList">
                                                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="input-group-btn">
                                                                <button class="btn btn-solid" >
                                                                    <i class="fa fa-search" (click)="savePreList1()"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                </form>
                                                
                                            </div>
                                            
                                    
                                        </div>
                                    </li>
                                    <!-- <li>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search">
                                        <div class="input-group-append">
                                          <button class="btn btn-secondary" style="border-radius: 0px!important;" type="button">
                                            <i class="fa fa-search"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </li> -->
                                    
                                    <li class="d-flex" style="margin-right: 19%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                                        <span class="menu-title" style="cursor: pointer;">Login</span>  <a href="javascript:;"  style="text-decoration: none !important;color: #0a0a0a !important; font-size: 13px;">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="29" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                        </a>
                                    </li> 
            
                                    <li class="d-flex" *ngIf="appService.userLoggedIn" style="margin-left: 0px;">
                                        <div class="dropdown">
                                            <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: #3C57A6 !important;"><span
                                                    class="lnr lnr-user">
                                                </span>
                                                {{ appService.userName }}
                                            </a>
                                            <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #0a0a0a !important;">My Account</a>
                                                <a *ngIf= "configs.title !== 'Cle' && configs.title != 'LinenRaj'" class="logout-cutom dropdown-item" routerLink="my-orders" style="color: #0a0a0a!important;">My Order</a>
                                                <a *ngIf= "configs.title === 'Cle' || configs.title === 'LinenRaj'" class="logout-cutom dropdown-item" routerLink="enquiry-status" style="color: #0a0a0a!important;">My Enquiry</a>
                                                <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: #0a0a0a!important;">Logout</a>
                                            </div>
                                        </div>
                                    </li>
            
                                    <li class="header-cart account-popup d-flex" style="position: relative;">   
                                        <span class="menu-title" style="margin-right: 2%;cursor: pointer;
                                        ">Cart</span>                                     
                                        <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: #0a0a0a!important;">
                                            <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>
                                                <span style="position: absolute;
                                                
                                                width: 18px;
                                                height: 18px;
                                                border-radius: 9px;
                                                top: -10px;
                                                right: 9%;" >
                                               
                                                    <span style="  color: #0b0b0b;
                                                    position: absolute;
                                                    left: 1.2rem;
                                                    font-size: 15px;
                                                    font-weight: bold;">{{appService.totalCartItems}}</span>
                                                </span>
                                            </span>  
                                             <!-- <span class="menu-title">
                                                Cart
                                            </span> -->
                                        </a>
                                        
                                        <app-mini-cart></app-mini-cart>
                                    </li>
                                </ul>
                        
                        </div>
                            <!-- <div class="float-right">
                                <button data-toggle="tooltip" data-placement="top" title="Upcoming Feature" class="btn btn-outline-warning shadow-sm" style="border-radius: 30px !important; font-size: 14px; padding: 5px 25px;font-weight: 500;" disabled>Quick Enquiry</button>
                            </div> -->
                        </div>
                        
                    </div>
            
                </header>
            </div>
            
        </div>
    </div>

    <div *ngIf="configs.title === 'Cle'" class="avonHome3">
        <div class="container-fluid-header-sticky-header">
            <div class="container-fluid header sticky-header cle-header" >
                 <span class="container-fluid header sticky-header cle-header" style="display: flex;
                 justify-content: center;font-weight:700">E-commerce portal of Indian Footwear(Leather And Non Leather) Products</span>
                <header>
                    <div class="flex-container">
                        <img [src]="logoImage" alt="Logo Image" class="custom-pointer cle-logo" height="50" alt="Logo" style="width: 72px!important; height: 100px!important;"
                                    [routerLink]="['/']">
                                    <div style="margin-left: 7px;">
                                        <span style="font-weight: bold;font-size: 15px;">Council For Leather Exports (CLE)</span>
                                        <div>
                                            <span style="font-weight: bold;font-size: 14px;">चर्म निर्यात परिषद</span>
                                        </div>
                                        <div>
                                            <span style="font-size: 12px;">(Sponsored by Ministry of Commerce &amp; Industry, Government of India)</span>
                                        </div>
                                    </div>   
                        <div class="header-row-one" style="width: 100%;">
                            <div class="container-fluid" style="padding-left: 0px !important;padding-right: 0px !important;">
                                <div class="inq-menu-st row ">
                                    <!-- <div class="inq-menu-st row justify-content-center"> -->
                                    <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                                            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                                            class="navbar-toggler"><span class="navbar-toggler-icon"></span><span>Menu</span></button>
                                        <div id="navbarNav" class="collapse navbar-collapse">
                                            <ul class="navbar-nav" style="padding-top:20px">
                                                <li class="nav-item cle-homenav2" id="home"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                                        href="#/" style="width: max-content!important;"><p style="font-size: 15px; font-weight: 500; color: black;">HOME</p></a></li>
                                                        <li class="header-cart account-popup1 cle-homenav2" id="shop" style="width: max-content!important;cursor: pointer;">
                                                            <a (click)="productsLists()" class="nav-link menu-text"><p style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">COMPANIES</p></a>
                                                            </li>
                                                        <li class="header-cart account-popup1 cle-homenav2" id="shop" style="width: max-content!important;">
                                                            <a href="javascript:;" class="nav-link menu-text" (click)="productsList()" style="display: flex;"><p style="font-size: 15px; font-weight: 500; color: black;">CATEGORY</p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down" style="color: black;"><polyline points="6 9 12 15 18 9"></polyline></svg></a>
                                                            <app-new-navbar [category]="fruits"></app-new-navbar>
                                                            </li>
                                                            <!-- <li class="header-cart account-popup1" id="shop" style="width: max-content!important;"> -->
                                                                <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p>BESTGIFTS</p></a> -->
                                                                <!-- <app-new-navbar [category]="vegetables"></app-new-navbar> -->
                                                                <!-- </li> -->
                                                <!-- <li class="header-cart account-popup1" id="shop">
                                                    <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">SHOP</a>
                                                     <app-new-navbar></app-new-navbar>
                                                    </li> -->
                                                    <!-- <li class="header-cart account-popup1" id="shop" style="width: max-content!important;"> -->
                                                        <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p>VALENTINE SPECIAL</p></a> -->
                                                         <!-- <app-new-navbar [category]="others"></app-new-navbar> -->
                                                        <!-- </li> -->
                                                        <li class="header-cart account-popup1 cle-homenav2" id="shop" style="width: max-content!important;">
                                                            <a href="#/investor" class="nav-link menu-text"><p style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">INVESTOR ENQUIRY</p></a>
                                                            </li>
                                                        <li class="header-cart account-popup1 cle-homenav2" id="shop" style="width: max-content!important;">
                                                            <a href="#/about-us" class="nav-link menu-text"><p style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">ABOUT US</p></a>
                                                            </li>
                                                            <li class="header-cart account-popup1 cle-homenav2" id="shop" style="width: max-content!important;">
                                                                <a href="#/contact-us" class="nav-link menu-text" ><p style="font-size: 15px; font-weight: 500; color: black;white-space: nowrap;">CONTACT US</p></a>
                                                                </li>
                                                        
                                                <!-- <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                                                        href="#/contact-us">CONTACT US</a></li> -->
                                                        
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <div class="col-3" style="margin-top: 2.5%; padding-right: 0px; padding-left: 0px;">
                
                                <ul class="d-flex cle-headericon">
                                    <div style="font-weight: 500!important;">Search</div>
                                    <li class="d-flex header-cart account-popup1" style="margin-right: 4%;
                                    margin-left: 2%;">
                                    
                                        <a href="javascript:;"  style="text-decoration: none !important;color:#0a0a0a !important;" (click)="search()">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                        </a>
                                        
                                        <div class="min-cart-product-wrapper popup-content2 " style="height:auto;overflow-y:visible!important;">
                                            <div class="row" style="border-bottom : 2px;margin: 5px;">
                                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                                    <div *ngFor="let category of allCategories">
                                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                                    </div>                                      
                                                                </div>
                                                              </div>
                                                            <input type="text" placeholder="Search here."
                                                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                            <div *ngIf="preList.length != 0">
                                                                <!-- <div class="autocomplete-items2"> -->
                                                                    <div class="autocomplete-items2">
                                                                    <div *ngFor="let preItem of preList">
                                                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="input-group-btn">
                                                                <button class="btn btn-solid" >
                                                                    <i class="fa fa-search" (click)="savePreList1()"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                </form>
                                                
                                            </div>
                                            
                                    
                                        </div>
                                    </li>
                                    <!-- <li>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search">
                                        <div class="input-group-append">
                                          <button class="btn btn-secondary" style="border-radius: 0px!important;" type="button">
                                            <i class="fa fa-search"></i>
                                          </button>
                                        </div>
                                      </div>
                                    </li> -->
                                    
                                    <li class="d-flex" style="margin-right: 19%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                                        <span class="menu-title" style="cursor: pointer;">Login</span>  <a href="javascript:;"  style="text-decoration: none !important;color: #0a0a0a !important; font-size: 13px;">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="29" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                        </a>
                                    </li> 
            
                                    <li class="d-flex" *ngIf="appService.userLoggedIn" style="margin-left: 0px;">
                                        <div class="dropdown">
                                            <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: #3C57A6 !important;"><span
                                                    class="lnr lnr-user">
                                                </span>
                                                {{ appService.userName }}
                                            </a>
                                            <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #0a0a0a !important;">My Account</a>
                                                <a *ngIf= "configs.title !== 'Cle' && configs.title != 'LinenRaj'" class="logout-cutom dropdown-item" routerLink="my-orders" style="color: #0a0a0a!important;">My Order</a>
                                                <a *ngIf= "configs.title === 'Cle' || configs.title === 'LinenRaj'" class="logout-cutom dropdown-item" routerLink="enquiry-status" style="color: #0a0a0a!important;">My Enquiry</a>
                                                <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: #0a0a0a!important;">Logout</a>
                                            </div>
                                        </div>
                                    </li>
            
                                    <li class="header-cart account-popup d-flex" style="position: relative;">   
                                        <span class="menu-title" style="margin-right: 2%;cursor: pointer;
                                        ">Cart</span>                                     
                                        <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: #0a0a0a!important;">
                                            <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>
                                                <span style="position: absolute;
                                                
                                                width: 18px;
                                                height: 18px;
                                                border-radius: 9px;
                                                top: -10px;
                                                right: 9%;" >
                                               
                                                    <span style="  color: #0b0b0b;
                                                    position: absolute;
                                                    left: 1.2rem;
                                                    font-size: 15px;
                                                    font-weight: bold;">{{appService.totalCartItems}}</span>
                                                </span>
                                            </span>  
                                             <!-- <span class="menu-title">
                                                Cart
                                            </span> -->
                                        </a>
                                        
                                        <app-mini-cart></app-mini-cart>
                                    </li>
                                </ul>
                        
                        </div>
                            <!-- <div class="float-right">
                                <button data-toggle="tooltip" data-placement="top" title="Upcoming Feature" class="btn btn-outline-warning shadow-sm" style="border-radius: 30px !important; font-size: 14px; padding: 5px 25px;font-weight: 500;" disabled>Quick Enquiry</button>
                            </div> -->
                        </div>
                        
                    </div>
            
                </header>
            </div>
            
        </div>
    </div>
    


    <div *ngIf="configs.title === 'LinenRaj' && routeurl!='/'">

       <div class="container-fluid-header-sticky-header">

           <div class="container-fluid header sticky-header cle-header" >

                

               <header class="container-fluid-header-sticky-header"  style="background-color:#fff;height: 9vw;display: block;">

                   <div class="flex-container header-linenraj">

                       <div  class="collapsenavbar-collapse-1">

                           <ul class="navbar-nav">

                               <li class="nav-item cle-homenav" id="home"><a class="nav-link menu-text" ng-reflect-router-link="/"

                                       href="#/" style="width: max-content!important;"><p class="hover-underline-animation" style="font-size: 10px; font-weight:600;font-family: lato;letter-spacing: 2px; color: black;margin-left: 60px;">HOME</p></a></li>

                           </ul>

                      

                       </div>

  

                       <img src="assets/images/linenraj/linen-logo.png" alt="Logo Image" class="custom-pointer aiema-logo" height="50" alt="Logo" style="width:100px!important; height:50px!important;margin-left:485px;box-shadow: none;"

                                   [routerLink]="['/']">

  

  

  

                          

                       <div class="header-row-one" style="width: 100%;">

                           <div class="container-fluid" style="padding-left: 0px !important;padding-right: 0px !important;">

                               <div class="inq-menu-st row ">

                                   <!-- <div class="inq-menu-st row justify-content-center"> -->

                                   <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"

                                           data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"

                                           class="navbar-toggler"><span class="navbar-toggler-icon"></span><span>Menu</span></button>

                                    

                                   </nav>

                               </div>

                           </div>

                           <div class="col-3" style="margin-top: 2.5%; padding-right: 0px; padding-left: 0px;">

              

                               <ul class="d-flex cle-headericon" >

                                   <!-- <div style="font-weight: 500!important;">Search</div> -->

                                   <li class="d-flex header-cart account-popup1" style="margin-right: 9%;

                                   margin-left: 2%;" >

                                  

                                       <a href="javascript:;"   style="text-decoration: none !important;color:#0a0a0a !important;">

                                         <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>

                                       </a>

                                      

                                       <div class="min-cart-product-wrapper popup-content2 " style="height:auto;overflow-y:visible!important;">

                                           <div class="row" style="border-bottom : 2px;margin: 5px;">

                                               <form  class="search-bar" style="width: 100%;">

                                                       <div class="input-group">

                                                           <div class="input-group-prepend">

                                                               <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">

                                                                   <div>

                                                                       <a class="dropdown-item" style="cursor:pointer;font-size: 14px !important;"></a>

                                                                   </div>                                      

                                                               </div>

                                                             </div>

                                                           <input type="text" placeholder="Search here."

                                                               class="form-control search-form-input" formControlName="search_term">

                                                           <div >

                                                               <!-- <div class="autocomplete-items2"> -->

                                                                   <div class="autocomplete-items2">

                                                                   <!-- <div *ngFor="let preItem of preList">

                                                                       <li class=""></li>

                                                                   </div> -->

                                                               </div>

                                                           </div>

                                                           <div class="input-group-btn">

                                                               <button class="btn btn-solid" >

                                                                   <i class="fa fa-search"></i>

                                                               </button>

                                                           </div>

                                                       </div>

                                               </form>

                                              

                                           </div>

                                          

                                  

                                       </div>

                                   </li>

                                   <!-- <li>

                                   <div class="input-group">

                                       <input type="text" class="form-control" placeholder="Search">

                                       <div class="input-group-append">

                                         <button class="btn btn-secondary" style="border-radius: 0px!important;" type="button">

                                           <i class="fa fa-search"></i>

                                         </button>

                                       </div>

                                     </div>

                                   </li> -->

                                  

                                   <li class="d-flex" style="margin-right:19px;    margin-left: 15px;" *ngIf="!appService.userLoggedIn" (click)="logIn()">

                                       <!-- <span class="menu-title" style="cursor: pointer;">Login</span>  -->

                                        <a href="javascript:;"  style="text-decoration: none !important;color: #0a0a0a !important; font-size: 13px;">

                                           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="29" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>

                                       </a>

                                   </li>

          

                                   <li class="d-flex" *ngIf="appService.userLoggedIn" style="margin-left: 0px;">

                                       <div class="dropdown">

                                           <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"

                                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: black !important;"><span

                                                   class="lnr lnr-user">{{ appService.userName.substring(0,18) }}...

                                               </span>

                                              

                                           </a>

                                           <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                                               <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #0a0a0a !important;">My Account</a>

                                               <a *ngIf= "configs.title != 'LinenRaj'" class="logout-cutom dropdown-item" routerLink="my-orders" style="color: #0a0a0a!important;">My Order</a>

                                               <!-- //<a *ngIf= "CONFIG.title === 'LinenRaj'" class="logout-cutom dropdown-item" routerLink="enquiry-status" style="color: #0a0a0a!important;">My Enquiry</a> -->

                                               <a class="logout-cutom dropdown-item" style="color: #0a0a0a!important;" (click)="logOut()">Logout</a>

                                           </div>

                                       </div>

                                   </li>

                                  

                                   <li class="header-cart account-popup d-flex" style="position: relative;">  

                                       <!-- <span class="menu-title" style="margin-right: 2%;cursor: pointer;

                                       ">Cart</span>                                      -->

                                       <a id="openbag" href="javascript:;"  data-toggle="modal" data-target="#bagModal" style="text-decoration: none !important;color: #0a0a0a!important;margin-left: 10px;">

                                           <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>

                                               <span style="position: absolute;

                                              

                                               width: 18px;

                                               height: 18px;

                                               border-radius: 9px;

                                               top: -10px;

                                               right: 9%;" >

<!--                                                

                                                   <span style="  color: #0b0b0b;

                                                   position: absolute;

                                                   left: 1.2rem;

                                                   font-size: 15px;

                                                   font-weight: bold;">{{appService.totalCartItems}}</span> -->

                                                   <span class="count-1">

                                                     <span class="count-number-1">{{appService.totalCartItems}}</span>

                                                 </span>

                                               </span>

                                           </span>  

                                            <!-- <span class="menu-title">

                                               Cart

                                           </span> -->

                                       </a>

                                      

                                       <!-- <app-mini-cart></app-mini-cart> -->

                                   </li>

                               </ul>

                      

                       </div>

                           <!-- <div class="float-right">

                               <button data-toggle="tooltip" data-placement="top" title="Upcoming Feature" class="btn btn-outline-warning shadow-sm" style="border-radius: 30px !important; font-size: 14px; padding: 5px 25px;font-weight: 500;" disabled>Quick Enquiry</button>

                           </div> -->

                       </div>

                      

                   </div>

          

               </header>

           </div>

          

       </div>

   </div>
<!-- </div> -->
<!-- 1 -->
<div class="container-fluid-header-sticky-header1" style="background-color:white;height: 12vw;margin-top: -40px;margin-bottom: 10%;
padding: 2vw;" *ngIf="configs.title === 'Cle'">
 <span style="background-color:white;height: 12vw;margin-top: -40px;
 padding: 2vw;font-size: 10px;font-weight: 800;">E-commerce portal of Indian Footwear(Leather And Non Leather) Products</span>
    <header>
        <div>
            <ul style="display: flex;">
<li style="margin-left: 1vw;"><span style="font-size: 7vw;cursor:pointer;color:#000000" (click)="openNav()">&#9776;</span>
</li>
<li>
    <div class="row">
        <span>
            <img [src]="logoImage" alt="Logo Image" style="width: 20vw;
            height: 17vw;
            padding: 1vw;
            margin-top: 1px;
            object-fit: contain;"class="logo-image custom-pointer" alt="Logo"
                    [routerLink]="['/']"> 
        </span>
        
            <span style="font-weight: bold;font-size: 10px;margin-left: -16px;">Council For Leather Exports (CLE) <br>
                <span style="font-weight: bold;font-size: 9px;">चर्म निर्यात परिषद</span><br>
                <span style="font-size: 8px;">(Sponsored by Ministry of Commerce &amp; <br>Industry, Government of India)</span>
            </span>
    </div>     
</li>
</ul>
<ul style="
display: flex;
transform: translate(260px, -59px);">
<li class="d-flex header-cart account-popup1">
                        <a href="javascript:;" (click)="search()">
                            <i aria-hidden="true" style="text-decoration: none !important;color: #000000 !important;    font-size: 5vw;
                            margin-left: 1vw;
                            margin-top: 2vw;" (click)="opensearchnavkub()"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg></i>
                        </a>
                        <div class="min-cart-product-wrapper popup-content2 " style="height:auto;overflow-y:visible!important;">
                            <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                    <div *ngFor="let category of allCategories">
                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                    </div>                                      
                                                </div>
                                              </div>
                                            <input type="text" placeholder="Search here."
                                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                            <div *ngIf="preList.length != 0">
                                                <div class="autocomplete-items2">
                                                    <div *ngFor="let preItem of preList">
                                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group-btn">
                                                <button class="btn btn-solid" >
                                                    <i class="fa fa-search" (click)="savePreList1()"></i>
                                                </button>
                                            </div>
                                        </div>
                                </form>
                                
                            </div>
                            
                    
                        </div>
                    </li>
                    <li class="d-flex" style="    margin-left: 4vw;
                    margin-top: 1.5vw;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                        <a href="javascript:;"  style="text-decoration: none !important;color: #0a0a0a !important">
                            <span style="font-size:4vw"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user" style="transform: translate(0px, -5px);"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>

                            </span>
                        </a>
                    </li>

                    <li class="d-flex" *ngIf="appService.userLoggedIn" style="margin-left: 0px;">
                        <div class="dropdown">
                            <a class="menu-title dropdown-toggle profile_name" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: #000000 !important;"><span
                                    class="lnr lnr-user">
                                </span>
                                {{ appService.userName.substring(0,4) }}
                            </a>
                            <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <div id="dropdown" class="dropdown-menu show" style=" transform: translate3d(-47px, -70px, 0px) !important;">
                                    <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #000000 !important;">My Account</a>
                                    <a  *ngIf= "configs.title !== 'Cle' && configs.title != 'LinenRaj'" class="logout-cutom dropdown-item" routerLink="my-orders" style="color: #000000 !important;">My Order</a>
                                    <a *ngIf= "configs.title === 'Cle' || configs.title === 'LinenRaj'" class="logout-cutom dropdown-item" routerLink="enquiry-status" style="color: #0a0a0a!important;">My Enquiry</a>
                                    <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: #000000 !important;">Logout</a>
                                </div>
                              
                            </div>
                        </div>
                    </li>
                    <li class="header-cart account-popup d-flex" style="position: relative;margin-left: -10px;">                                        
                        <a href="javascript:;" routerLink="/cart" data-toggle="modal" data-target="#bagModal" style="text-decoration: none !important;color: #000000 !important;">
                            <span style="font-size: 5vw;
                            margin-top: 2vw;
                            margin-left: 4vw;"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-shopping-bag"><path d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"></path><line x1="3" y1="6" x2="21" y2="6"></line><path d="M16 10a4 4 0 0 1-8 0"></path></svg>

                                <span style="position: absolute;
                               
                                width: 3vw;
                                height: 2.5vw;
                                border-radius: 1vw;" >
                               
                                    <span style="color: #090909;
                                    position: absolute;
                                    left: 0.1rem;
                                    top: -8px;
                                    font-size: 4vw;
                                    font-weight: bold;">{{appService.totalCartItems}}</span>
                                </span>
                            </span>  
                             <!-- <span class="menu-title">
                                Cart
                            </span> -->
                        </a>
                        <app-mini-cart></app-mini-cart>
                    </li>
                    </ul>

        </div>
        <div id="mySidenav1" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closeNav1()"><p>&#8592;</p></a>
            <a href="javascript:void(0)" class="nav-link menu-text" style="cursor:pointer;" (click)="productsListkubendran2('myfruits1','FRUITS')"><p>Category &#8594;</p></a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myfruits1','FRUITS')"><p>Companies &#8594;</p></a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myfruits1','FRUITS')"><p>Investor Enquiry&#8594;</p></a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myfruits1','FRUITS')"><p>About Us &#8594;</p></a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myfruits1','FRUITS')"><p>Contact Us &#8594;</p></a>
                    
      
          </div>
          <div id="myfruits1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;category </span>
            
            <app-new-navbar  [category]="fruitsres"></app-new-navbar>
        
          </div>
          <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;Companies</span>
            
            <app-new-navbar  [category]="vegetablesres"></app-new-navbar>
        
          </div>
          <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;Investor Enquiry </span>
            
            <app-new-navbar  [category]="vegetablesres"></app-new-navbar>
        
          </div>
          <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;About Us </span>
            
            <app-new-navbar  [category]="vegetablesres"></app-new-navbar>
        
          </div>
          <div id="myothers1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;Contact Us </span>
            
            <app-new-navbar  [category]="othersres"></app-new-navbar>
        
          </div>
          <div id="searchnav" class="sidenav" style="background-color: rgb(255, 255, 255)!important;">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closesearchnavkub()"><p>&#8592;</p></a>
            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
            <input type="text" placeholder="Search here."
            class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
            <ul *ngFor="let preItem of preList">
                <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">{{preItem.name}}</li>
            </ul>
            </form>

        </div>
        <div id="mySidenav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closeNav()"><p>&#8592;</p></a>
            <a class="nav-link menu-text" ng-reflect-router-link="/"
             href="#/" (click)="closeNav()"><p>Home</p></a>
             <a href="javascript:void(0)" class="nav-link menu-text" (click)="bestgifts()"><p>Companies</p></a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myfruits')"><p>Category &#8594;</p></a>
            <a href="javascript:void(0)" class="nav-link menu-text" href="#/investor" (click)="routeTo()"><p>Investor Enquiry</p></a>
            <a href="javascript:void(0)" class="nav-link menu-text" href="#/about-us" (click)="routeTo()"><p>About Us</p></a>
            <a href="javascript:void(0)" class="nav-link menu-text" href="#/contact-us" (click)="routeTo()"><p>Contact Us</p></a>
                       
      
          </div> 
          <div id="myvegetables" class="sidenav" style="background-color:#fff!important">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav()">&#8592;Category</span>
            
            <app-new-navbar  [category]="vegetablesres"></app-new-navbar>
        
          </div> 
          <div id="myfruits" class="sidenav" style="background-color:#fff!important">
            <span style="font-size: 5vw;cursor:pointer;color:#805425" (click)="openNav()">&#8592;Back </span>
            
            <app-new-navbar  [category]="fruitsres"></app-new-navbar>
        
          </div>
          <div id="myvegetables" class="sidenav" style="background-color:#fff!important">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav()">&#8592;Investor Enquiry</span>
            
            <app-new-navbar  [category]="vegetablesres"></app-new-navbar>
        
          </div>
          <div id="myvegetables" class="sidenav" style="background-color:#fff!important">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav()">&#8592;About Us </span>
            
            <app-new-navbar  [category]="vegetablesres"></app-new-navbar>
        
          </div>
          <div id="myothers" class="sidenav" style="background-color:#fff!important">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav()">&#8592;Contact Us </span>
            
            <app-new-navbar  [category]="othersres"></app-new-navbar>
        
          </div>
        
            

    </header>
</div>
<div class="container-fluid-header-sticky-header" *ngIf="configs.title === 'BestGifts'" style="background-color:#fff;height: 5vw;">
    <header>
        <div class="row header_bestgift" >
            <div class="col-1">
                <img [src]="logoImage" alt="Logo Image" style="margin-left: 40px;"class="logo-image custom-pointer" height="80" width="80" alt="Logo"
                [routerLink]="['/']"> 
            </div>
            <div class="col-6" *ngIf="configs.title === 'BestGifts'" style="margin-left:auto"> 
                <div class="inq-menu-st row justify-content-center">
                    <nav class="navbar navbar-expand-lg navbar-light"><button type="button" data-toggle="collapse"
                            data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                            class="navbar-toggler"><span class="navbar-toggler-icon"></span><span>Menu</span></button>
                        <div id="navbarNav" class="collapse navbar-collapse">
                            <ul class="navbar-nav" style="padding:20px">
                                <li class="nav-item" id="home"><a class="nav-link menu-text" ng-reflect-router-link="/"
                                        href="#/" style="width: max-content!important;"><p>HOME</p></a></li>
                                        <li class="header-cart account-popup1" id="shop" style="width: max-content!important;">
                                            <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p>SHOP</p></a>
                                            <app-new-navbar [category]="fruits"></app-new-navbar>
                                            </li>
                                            <!-- <li class="header-cart account-popup1" id="shop" style="width: max-content!important;"> -->
                                                <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p>BESTGIFTS</p></a> -->
                                                <!-- <app-new-navbar [category]="vegetables"></app-new-navbar> -->
                                                <!-- </li> -->
                                <!-- <li class="header-cart account-popup1" id="shop">
                                    <a href="javascript:;" class="nav-link menu-text" (click)="productsList()">SHOP</a>
                                     <app-new-navbar></app-new-navbar>
                                    </li> -->
                                    <!-- <li class="header-cart account-popup1" id="shop" style="width: max-content!important;"> -->
                                        <!-- <a href="javascript:;" class="nav-link menu-text" (click)="productsList()"><p>VALENTINE SPECIAL</p></a> -->
                                         <!-- <app-new-navbar [category]="others"></app-new-navbar> -->
                                        <!-- </li> -->
                                        <li class="header-cart account-popup1" id="shop" style="width: max-content!important;">
                                            <a href="#/about-us" class="nav-link menu-text"><p>ABOUT US</p></a>
                                            </li>
                                            <li class="header-cart account-popup1" id="shop" style="width: max-content!important;">
                                                <a href="#/contact-us" class="nav-link menu-text"><p>CONTACT US</p></a>
                                                </li>
                                        
                                <!-- <li class="nav-item" id="contact"><a class="nav-link menu-text" ng-reflect-router-link="contact-us"
                                        href="#/contact-us">CONTACT US</a></li> -->
                                        
                            </ul>
                        </div>
                    </nav>
                </div>
                </div>
            <!-- <div class="col-3" style="margin-top: 2%"> -->
                <div class="col-3" style="margin-top: 2%">
                
                    <ul class="d-flex">
                        <li class="d-flex header-cart account-popup1" style="margin-right: 18%;
                        margin-left: 20%;">
                            <a href="javascript:;"  style="text-decoration: none !important;color:#3C57A6 !important;" (click)="search()">
                                <i class="fa fa-search" aria-hidden="true"></i>
                            </a>
                            <div class="min-cart-product-wrapper popup-content2 " style="height:auto;overflow-y:visible!important;">
                                <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                    <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                        <div *ngFor="let category of allCategories">
                                                            <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                        </div>                                      
                                                    </div>
                                                  </div>
                                                <input type="text" placeholder="Search here."
                                                    class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                                <div *ngIf="preList.length != 0">
                                                    <!-- <div class="autocomplete-items2"> -->
                                                        <div class="autocomplete-items2">
                                                        <div *ngFor="let preItem of preList">
                                                            <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="input-group-btn">
                                                    <button class="btn btn-solid" >
                                                        <i class="fa fa-search" (click)="savePreList1()"></i>
                                                    </button>
                                                </div>
                                            </div>
                                    </form>
                                    
                                </div>
                                
                        
                            </div>
                        </li>
                        
                        <!-- <li>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Search">
                            <div class="input-group-append">
                              <button class="btn btn-secondary" style="border-radius: 0px!important;" type="button">
                                <i class="fa fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </li> -->
                        
                        <li class="d-flex" style="margin-right: 12%;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                            <a href="javascript:;"  style="text-decoration: none !important;color: #3C57A6 !important;">
                                <span class="fa fa-user" style="font-size:25px">
                                </span> Login
                            </a>
                        </li>

                        <li class="d-flex" *ngIf="appService.userLoggedIn" style="margin-left: 0px;">
                            <div class="dropdown">
                                <a class="menu-title dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: #3C57A6 !important;"><span
                                        class="lnr lnr-user">
                                    </span>
                                    {{ appService.userName }}
                                </a>
                                <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #3C57A6 !important;">My Account</a>
                                    <a class="logout-cutom dropdown-item" routerLink="my-orders" style="color: #3C57A6!important;">My Order</a>
                                    
                                    <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: #3C57A6!important;">Logout</a>
                                </div>
                            </div>
                        </li>

                        <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                            <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: #3C57A6!important;">
                                <span class="fa fa-shopping-basket" style="font-size:25px">
                                    <span style="position: absolute;
                                    background: #FBCB0C;
                                    width: 18px;
                                    height: 18px;
                                    border-radius: 9px;
                                    top: -10px;
                                    right: 9%;" >
                                   
                                        <span style="  color: #fff;
                                        position: absolute;
                                        left: 0.2rem;
                                        font-size: 14px;
                                        font-weight: bold;">{{appService.totalCartItems}}</span>
                                    </span>
                                </span>  
                                 <!-- <span class="menu-title">
                                    Cart
                                </span> -->
                            </a>
                            <app-mini-cart></app-mini-cart>
                        </li>
                    </ul>
            
            </div>
        </div>
        
            

    </header>
</div>


<div class="container-fluid-header-sticky-header1 best_gifts" *ngIf="configs.title === 'BestGifts'">
    <header>
        <div>
<ul style="display:flex">
<li style="margin-top: -5px;"><span style="font-size: 7vw;cursor:pointer;color:#3C57A6" (click)="openNav()">&#9776;</span>
</li>
<li style="margin-left: 6px;">
    <div class="row">
        <span>
            <img  [src]="logoImage" alt="Logo Image" class="logo-image custom-pointer gifts_img" alt="Logo"
                    [routerLink]="['/']"> 
        </span>
    </div>   
</li>
<!-- 
<li class="d-flex header-cart account-popup1">
                        <a href="javascript:;" (click)="search()">
                            <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: #3C57A6 !important;    font-size: 5vw;
                            margin-left: 1vw;
                            margin-top: 2vw;"></i>
                        </a>
                        <div class="min-cart-product-wrapper popup-content2 " style="height:auto;overflow-y:visible!important;">
                            <div class="row" style="border-bottom : 2px dashed #f2f2f2;margin: 5px;">
                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                    <div *ngFor="let category of allCategories">
                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                    </div>                                      
                                                </div>
                                              </div>
                                            <input type="text" placeholder="Search here."
                                                class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
                                            <div *ngIf="preList.length != 0">
                                                <div class="autocomplete-items2">
                                                    <div *ngFor="let preItem of preList">
                                                        <li class="" (click)="savePreList(preItem)">{{preItem.name}}</li>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group-btn">
                                                <button class="btn btn-solid" >
                                                    <i class="fa fa-search" (click)="savePreList1()"></i>
                                                </button>
                                            </div>
                                        </div>
                                </form>
                                
                            </div>
                            
                    
                        </div>
                    </li> -->

                    <li class="d-flex header-cart account-popup1">
                        <a href="javascript:;" (click)="search()">
                            <i class="fa fa-search" aria-hidden="true" style="text-decoration: none !important;color: black !important;    font-size: 5vw;
                            margin-left: 1vw;
                            margin-top: 2vw;" (click)="opensearchnavkub()"></i>
                        </a>
                        <div class="min-cart-product-wrapper popup-content2" style="height:auto;overflow-y: visible !important;">
                            <div class="row" style="margin: 5px;">
                                <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="dropdown-menu autocomplete-items" style="width: 100%! important;">
                                                    <div *ngFor="let category of allCategories">
                                                        <a class="dropdown-item" (click)="navigateToList(category)"  style="cursor:pointer;font-size: 14px !important;">{{category.name}}</a>
                                                    </div>                                      
                                                </div>
                                              </div>
                                        </div>
                                </form>
                            </div>
                        </div>
                    </li>



                    <li class="d-flex" style="    margin-left: 4vw;
                    margin-top: 1.5vw;" *ngIf="!appService.userLoggedIn" (click)="logIn()">
                        <a href="javascript:;"  style="text-decoration: none !important;color: #3C57A6 !important">
                            <span class="fa fa-user" style="font-size:6vw">
                            </span>
                        </a>
                    </li>

                    <li class="d-flex" *ngIf="appService.userLoggedIn" style="margin-left: 0px;">
                        <div class="dropdown">
                            <a class="menu-title dropdown-toggle profile_name" type="button" id="dropdownMenuButton"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="text-decoration: none !important;color: #3C57A6 !important;"><span
                                    class="lnr lnr-user">
                                </span>
                                {{ appService.userName.substring(0,4)}}...
                            </a>
                            <div id="dropdown" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="logout-cutom dropdown-item" routerLink="my-account" style="color: #3C57A6 !important;">My Account</a>
                                <a class="logout-cutom dropdown-item" routerLink="my-orders" style="color: #3C57A6 !important;">My Order</a>
                                
                                <a class="logout-cutom dropdown-item" (click)="logOut()" style="color: #3C57A6 !important;">Logout</a>
                            </div>
                        </div>
                    </li>
                    <li class="header-cart account-popup d-flex" style="position: relative;">                                        
                        <a href="javascript:;" routerLink="/cart" style="text-decoration: none !important;color: #3C57A6 !important;">
                            <span class="fa fa-shopping-basket" style="font-size: 5vw;
                            margin-top: 2vw;
                            margin-left: 4vw;">
                                <span style="position: absolute;
                                background: #FBCB0C;
                                width: 3vw;
                                height: 2.5vw;
                                border-radius: 1vw;" >
                               
                                    <span style="color: #fff;
                                    position: absolute;
                                    left: 0.3rem;
                                    font-size: 2vw;
                                    font-weight: bold;">{{appService.totalCartItems}}</span>
                                </span>
                            </span>  
                             <!-- <span class="menu-title">
                                Cart
                            </span> -->
                        </a>
                        <app-mini-cart></app-mini-cart>
                    </li>
</ul>

        </div>
        <div id="mySidenav1" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closeNav1()"><p>&#8592;</p></a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myfruits1','FRUITS')"><p>SHOP &#8594;</p></a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myfruits1','FRUITS')"><p>BESTGIFTS &#8594;</p></a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran2('myfruits1','FRUITS')"><p>VALETINE SPECIAL &#8594;</p></a>
                    
      
          </div>
          <div id="myfruits1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;SHOP </span>
            
            <app-new-navbar  [category]="fruitsres"></app-new-navbar>
        
          </div>
          <div id="myvegetables1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;BESTGIFTS </span>
            
            <app-new-navbar  [category]="vegetablesres"></app-new-navbar>
        
          </div>
          <div id="myothers1" class="sidenav">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav1()">&#8592;VALETINE SPECIAL </span>
            
            <app-new-navbar  [category]="othersres"></app-new-navbar>
        
          </div>
        <div id="mySidenav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closeNav()"><p>&#8592;</p></a>
            <a class="nav-link menu-text" ng-reflect-router-link="/"
                                    href="#/" (click)="closeNav()"><p>HOME</p></a>
                                    <a href="javascript:void(0)" class="nav-link menu-text" (click)="productsListkubendran1('myfruits')"><p>SHOP &#8594;</p></a>
            
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="bestgifts()"><p>BESTGIFTS</p></a>
            <a href="javascript:void(0)" class="nav-link menu-text" (click)="bestgifts()"><p>VALETINE SPECIAL</p></a>
                       
      
          </div>  
          <div id="myfruits" class="sidenav" style="background-color:#fff!important">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav()">&#8592;SHOP </span>
            
            <app-new-navbar  [category]="fruitsres"></app-new-navbar>
        
          </div>
          <div id="myvegetables" class="sidenav" style="background-color:#fff!important">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav()">&#8592;BESTGIFTS </span>
            
            <app-new-navbar  [category]="vegetablesres"></app-new-navbar>
        
          </div>
          <div id="myothers" class="sidenav" style="background-color:#fff!important">
            <span style="font-size: 5vw;cursor:pointer;color:white" (click)="openNav()">&#8592;VALETINE SPECIAL </span>
            
            <app-new-navbar  [category]="othersres"></app-new-navbar>
        
          </div>
          <div id="searchnav" class="sidenav" style="background-color:#fff">
            <a href="javascript:void(0)" style="float:right;font-size:50px" class="nav-link menu-text" (click)="closesearchnavkub()"><p>&#8592;</p></a>
            <form [formGroup]="searchForm" class="search-bar" style="width: 100%;">
            <input type="text" placeholder="Search here."
            class="form-control search-form-input" formControlName="search_term" (keyup)="getPreList()">
            <ul *ngFor="let preItem of preList">
                <li class="" (click)="savePreList(preItem)" style="color:black;    margin-left: 10px !important;">{{preItem.name}}</li>
            </ul>
            </form>
          </div>
            

    </header>
</div>
<button id="sendquery-model-vimpro" style="display: none" type="button" class="btn btn-primary" data-toggle="modal"
  data-target="#sendqueryModel-vimpro">
  Launch demo modal
</button>
<div class="modal fade" id="sendqueryModel-vimpro" tabindex="-1" role="dialog" aria-labelledby="statusModelTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document" style="
      max-width: 640px !important;
      position: relative !important;
      bottom: -30px !important;
      -webkit-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;
      -moz-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;
      -o-transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;
      transition: opacity 0.3s ease-out, bottom 0.3s ease-out !important;
    ">
    <div *ngIf="configs.title === 'Vimpro'" class="modal-content status-model-container">
      <div class="modal-body" style="border: none !important">
        <div class="model-content m-content">
          <button #closeModal type="button" (click)="close_vimpro_model()" class="close" data-dismiss="modal" aria-label="Close">
            <span style="font-size: 1.6rem !important" aria-hidden="true">&times;</span>
          </button>
          <div class="title-container">
            <div class="m-title">Query Form</div>
            
          </div>
          <form [formGroup]="sendquery_vimpro" class="gift-form" novalidate>
            <div class="form-group row">
              <div class="col-md-4">
                <label for="customer_name" class="form-label">Name <span class="required">*</span></label>
              </div>
              <div class="col-md-7">
                <input type="text" class="form-control" id="customer_name" [class.is-invalid]="f_vimpro.customer_name.invalid&&validateForm"
                  formControlName="customer_name" required />
                <div class="invalid-feedback" *ngIf="f_vimpro.customer_name.errors">
                  <div *ngIf="f_vimpro.customer_name.errors.required">Name is required.</div>
                  <div *ngIf="f_vimpro.customer_name.errors.maxlength">
                    Name can't be more than 30 characters long.
                  </div>
                  <div *ngIf="f_vimpro.customer_name.errors.pattern">
                   Enter a valid name
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
                <div class="col-md-4">
                  <label for="company_name" class="form-label">Company Name</label>
                </div>
                <div class="col-md-7">
                  <input type="text" class="form-control" id="company_name"
                    formControlName="company_name" />
                </div>
              </div>
            <div class="form-group row">
              <div class="col-md-4">
                <label for="customer_email" class="form-label">Email<span class="required">*</span></label>
              </div>
              <div class="col-md-7">
                <input type="email" class="form-control" id="customer_email" [class.is-invalid]="f_vimpro.customer_email.invalid&&validateForm"
                  formControlName="customer_email" maxlength="50" required />
                <div class="invalid-feedback" *ngIf="f_vimpro.customer_email.errors">
                  <div *ngIf="f_vimpro.customer_email.errors.required">Email is required.</div>
                  <div *ngIf="f_vimpro.customer_email.errors.pattern">
                   Please enter a valid email
                  </div>
                  <!-- <div *ngIf="f_vimpro.customer_email.errors.email || f_vimpro.customer_email.errors.pattern">
                    Email must be a valid email address
                  </div> -->
                </div>
              </div>
            </div>
                 <div class="form-group row">
              <div class="col-md-4">
                <label for="customer_mobile" class="form-label">Mobile<span class="required">*</span></label>
              </div>
              <div class="col-md-7">
                <input type="number" class="form-control" id="customer_mobile" [class.is-invalid]="f_vimpro.customer_mobile.invalid&&validateForm"
                  formControlName="customer_mobile" maxlength="50" required />
                <div class="invalid-feedback" *ngIf="f_vimpro.customer_mobile.errors">
                  <div *ngIf="f_vimpro.customer_mobile.errors.required">Mobile is required.</div>
                  <div *ngIf="f_vimpro.customer_mobile.errors.max||f_vimpro.customer_mobile.errors.min">
                     Mobile should be 10 characters long.
                  </div>
                  <!-- <div *ngIf="f_vimpro.customer_mobile.errors.email || f_vimpro.customer_mobile.errors.pattern">
                    Mobile must be valid
                  </div> -->
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-4">
                <label for="category" class="form-label">Query<span class="required">*</span></label>
              </div>
              <div class="col-md-7">
                <textarea class="form-control" id="queries" [class.is-invalid]="f_vimpro.queries.invalid&&validateForm"
                  formControlName="queries" required rows="5"></textarea>
                <div class="invalid-feedback" *ngIf="f_vimpro.queries.errors">
                  <div *ngIf="f_vimpro.queries.errors.required">
                    Query is required.
                  </div>
                  <div *ngIf="f_vimpro.queries.errors.maxlength">
                    Query can't be more than 600 characters long.
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-bottom: 45px">
              <button class="btn btn-primary" style="
                  border-radius: 5px !important;
                  font-size: 1rem;
                  padding: 5px 25px;
                  margin-top: 5px;
                  float: right;
                " (click)="saveForm_vimpro()">
                SUBMIT
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
    <!-- </div> -->
<div *ngIf="configs.title=='LinenRaj'">
     
    <!-- on click the bag -->
<div class="modal fade" id="bagModal" tabindex="-1" aria-labelledby="bagModal" aria-hidden="true">
    <div class="modal-dialog product_bag">
      <div class="modal-content product_bag_slide">
        <div class="modal-header">
          <h5 class="modal-title " id="bagModal">YOUR BAG</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closebeforecheck">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
  
          <div style="display: flex;">
            <div>
                <img src="https://ecbee-labes-images1.s3.ap-south-1.amazonaws.com/7.jpg" style="width: 40%;" >

            </div>
            
            <div style="position: relative;right: 23%;">
                <p style="white-space: nowrap;font-size:13.px-small;font-family:'Lato', sans-serif !important;">Linen Shirts</p>
                <p style="white-space: nowrap;font-size:xx-small;font-family:'Lato', sans-serif !important;    margin-top: 10px;">Beige / XL </p>
                <p style="white-space: nowrap;margin-top: 10px;">₹ 2,000 </p>
            
            </div>
            <div>
                <div style="display: flex;position: relative;right: 20%;top: 30px;">
                    <img src="https://cdn.shopify.com/s/files/1/0618/3183/9957/files/pen.png?v=1648532958" height="16"  style="box-shadow:none">
                    <img src="https://cdn.shopify.com/s/files/1/0618/3183/9957/files/cross.png?v=1646738334" style="height: 16px;box-shadow:none;position:relative;left: 20px;">
    
                </div>
                <div class="mini_cart_actions_ln" style="min-width: 70px;max-width: 70px;border: 1px solid #ddd;position: relative;top: 39px;right: 20px;border-radius: 7px;">
                    <div class="" 
                  >
                  <span>
                    <i class="fa fa-minus mr-3" 
                      style="font-size: 10px; cursor: pointer;position: relative;left: 5px;"></i></span>
                  <span class="inq-sp-2"> 1</span>
                  <span>
                    <i class="fa fa-plus ml-3" 
                      style="font-size: 10px; cursor: pointer"></i></span>
                </div>
    
                </div>
            </div>
            
            
           
           
          
           
          <div>
  
           
           
    
          </div>
         
        
          
        
        
        
        
        
        
        </div>
        
      </div>
      <div></div>
      <div class="modal-footer" style="display: contents">
        <button style="margin-left: 10%;" type="button" class="btn btn-secondary"  (click)="routeTo2()" class="cart_bt">view bag</button>
        <button  style="margin-left: 10%;"type="button" class="btn btn-primary" (click)="routeTo1()" class="ckt_bt">Proceed to checkout</button>
      </div>
    </div>
  </div>

</div>
</div>