export const config = {
    title: 'Tams',
    name: "TAMS",
    image: '',
    logo: 'https://d18xkwaipu1whh.cloudfront.net/TamsAsset/ILY-GREEN_LOGO2.jpg',
    DOMAIN_NAME : 'https://tamsfoodsekart.ecbee.net',
    USER_ID : 18,
    STORE_ID: 1,
    ORGANIZATION_ID:1,
    default_product_image: "",
    product_path:"/product",
    showTableBookingBtn:false,
    noImageAvailable:"https://d18xkwaipu1whh.cloudfront.net/TamsAsset/NoImage.jpg",
    pattern1: "!@#$%^",
    pattern2: "8172",
    pattern3: "tamsfoods",

    HOME_SETTINGS: {
      banner: [
        {
          name: "Home Banner",
          image: "https://d18xkwaipu1whh.cloudfront.net/CommonAsset/banner.jpg",
          quote: "<h3>Good Food <br> Good Feelings</h3><p>with <span>TAMS</span></p>",
          button: {
            name: "CHECK PRODUCTS",
            link: "product"
          }
        }
      ],
      about_st: [
        {
          title: "Direct to Home",
          image: "https://d18xkwaipu1whh.cloudfront.net/TamsAsset/homenew1.jpg",
          text: "Get your iLY products delivered directly to your home"
        },
        {
          title: "Patented Technology",
          image: "https://d18xkwaipu1whh.cloudfront.net/TamsAsset/homenew3.jpg",
          text: "Six month shelf life. Just store it in an airtight container"
        },
        {
          title: "Easy Batter Preperation",
          // image: "assets/images/vendor/home4.svg",
          image: "https://d18xkwaipu1whh.cloudfront.net/TamsAsset/homenew4.jpg",
          // text: "Just add the required amount of water and ferment for 12 hours and your iLY idly and dosa batter is ready"
          text: "Add 1 1/4 to 1 1/2 cup of water for 1 cup flour. Ferment it for 12 hours"
        },
        {
          title: "High Quality Ingredients",
          // image: "assets/images/vendor/home2.svg",
          image: "https://d18xkwaipu1whh.cloudfront.net/TamsAsset/homenew2.jpg",
          // text: "iLY uses the best raw materials to prepare the best ready mix available in the market"
          text: "iLY guarantees the use of 100% natural quality raw materials"

        },
      ],
      gutters: [
        {
          image: "https://d18xkwaipu1whh.cloudfront.net/TamsAsset/ily 1.jpg"
        },
        {
          image: "https://d18xkwaipu1whh.cloudfront.net/TamsAsset/ily 3.jpg"
        },
        {
          image: "https://d18xkwaipu1whh.cloudfront.net/TamsAsset/ily 4.jpg"
        },
        {
          image: "https://d18xkwaipu1whh.cloudfront.net/TamsAsset/ily 5.jpg"
        }
      ],
      testimonials: [
        {
          title: "love from iLY family",
          comment: "I got a very quick of delivery. The Product is awesome. <br>It's excellent quality and value for your money.Highly recommended. Good job team iLY",
          name: "Swati Savera"
        }
      ],
      float_button: {
        show: false,
        image: "assets/images/tams/online.svg",
        link: "/product"
      }
    },
    menu: [
      {
        id: "home",
        label: "Home",
        link: "/"
      },
      {
        id: "products",
        label: "FLOUR MIXES",
        link: "product"
      },
      {
        id: "about",
        label: "About Us",
        link: "about-us"
      },
      {
        id: "contact",
        label: "Contact Us",
        link: "contact-us"
      }
    ],
    footer: [
      {
        id: "about",
        label: "About",
        link: "about-us"
      },
      {
        id: "contact",
        label: "Contact Us",
        link: "contact-us"
      },
      {
        id: "faq",
        label: "FAQ",
        link: "FAQs"
      },
      {
        id: "termsandconditions",
        label: "Terms & conditions",
        link: "terms-and-condition"
      },
      {
        id: "privacypolicy",
        label: "Privacy Policy",
        link: "privacy-policy"
      },
      {
        id: "cancellationPolicy",
        label: "Cancellation Policy",
        link: "cancellation-policy"
      },
      {
        id: "shippinganddelivery",
        label: "Shipping & Delivery",
        link: "shipping-and-delivery"
      },
      {
        id: "returnPolicy",
        label: "Return & Exchange Policy",
        link: "return-policy"
      }
    ]
  };
